import { Component, Input, ViewChild, SimpleChange, OnChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { EntityRelationship } from '@regsys/web/lib/e-commerce/models/catalog/entity-relationship.model';
import { RegSysDataSource, RegsysWebConfig } from '@regsys/web';
import { SortGrid } from '../../../shared/functions/sort/src/sort-grid.function';
import { governmentFunding } from '../../../../models/government-funding.model';



@Component({
    selector: 'regsys-government-funding',
    templateUrl: './entity-government-funding.component.html'
})
export class EntityGovernmentFundingComponent implements OnChanges {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) gridSort: MatSort;

    @Input() governmentFundings: governmentFunding[];

    gridgovernmentFundings: governmentFunding[];
    displayedColumns = ['governmentFundingSource', 'amount'];
    dataSource: RegSysDataSource;

    private formatting: any;

    constructor(regsysConfig: RegsysWebConfig) {
        this.formatting = regsysConfig.config.formattingOptions;
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if (changes.governmentFundings) {
            if (this.paginator) {
                this.paginator.pageIndex = 0;
            }

            this.gridgovernmentFundings = SortGrid({ active: 'attributeItemId', direction: 'asc' }, this.governmentFundings, this.formatting);
            this.dataSource = new RegSysDataSource(this.gridgovernmentFundings, this.paginator, this.gridSort);
        }
    }

    handlePaging(event: any) {
        // TODO: Find a better way to do this
        this.dataSource = new RegSysDataSource(this.gridgovernmentFundings, this.paginator, this.gridSort);
    }
}
