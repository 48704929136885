export class SubscriberItem {
    personInd:string;
    occupation: string;
    shares: string;
    fees: string;
    entityFirstName:string;
    entityMiddleName:string;
    entityLastName:string;
    regAddressOneLine:string;
    regAddressId :number;
    regAddressAttentionTo:string;
    regAddressLine1 :string;
    regAddressLine2 :string;
    regAddressLine3 :string;
    regAddressCity :string;
    regAddressState :string;
    regAddressStateId: number;
    regAddressProvId: number;
    regAddressCountry:string;
    regAddressCountryId :number;
    regAddressPostcode :string;

    // For Existing entity (Organization)
    entityId:number ;
    entityRegNumber:string;
    entityRegName:string;



}



