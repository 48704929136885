import { Component, Input } from '@angular/core';
import { relationship } from '../../../../models/relationship.model';


@Component({
    selector: 'regsys-entity-employer-details',
    templateUrl: './entity-employer-details.component.html'
})
export class EntityEmployerDetailsComponent {
    @Input() entity: relationship;
}
