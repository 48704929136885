import { Address } from "./address.model"; 

export class Entity {
    addresses: Address[] = [];

    constructor(
        public entityId: number,
        public entityRegNumber: string,
        public name: string,
        public status: string,
        public type: string,
        public regDate: Date,
        public entityTypeId: number,
        public expiryDate: Date,
        public annualReturnDueDate: Date,
        public personInd: boolean,
        public entityStatusEffectiveDate: Date,
        public entityNextReturnDate?: Date,
        public entityRegName?: string,
        public registeredNumber?: string,
        public registeredName?: string,
        public entityStatusDesc?: string,
        public entityTypeDesc?: string,
        public isExpectingRenewal?: boolean) { }

    addAddress(address: Address): void {
        if (!address) {
            return;
        }

        this.addresses.push(address);
    }

    cleanURL(): string {
        const name = this.registeredName || this.name;
        const cleanedName = name.toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\s+/g, '-')
            .toLowerCase()
            .replace(/&/g, '-and-')
            .replace(/[^a-z0-9\-]/g, '')
            .replace(/-+/g, '-')
            .replace(/^-*/, '')
            .replace(/-*$/, '');
        return `${this.entityId}-${cleanedName}`;
    }
}
