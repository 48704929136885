import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// @Regsys Package
import { RegsysHttp, PostRequest } from '@regsys/http';

import { environment } from '../../environments/environment';

@Injectable()
export class HomeSearchService {

    constructor(private regsysHttp: RegsysHttp) { }

    entityQuickSearch(term: string): Observable<any> {
        const request: PostRequest = {
            url: `${environment.apiUrl}entity/quick-search`,
            parameters: {
                searchText: term
            }
        };
        return this.regsysHttp.doPost(request);
    }
}
