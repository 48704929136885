import { Component } from '@angular/core';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

import { NotificationAggregator, Notification } from '@regsys/notifications';

@Component({
    selector: 'regsys-notification',
    templateUrl: './app-notifications.component.html',
    animations: [
        trigger('visibilityChanged', [
            state('shown', style({ display: 'block', opacity: 1 })),
            state('hidden', style({ display: 'none', opacity: 0 })),
            transition('shown => hidden', animate('600ms')),
            transition('hidden => shown', animate('600ms'))
        ])
    ]
})
export class AppNotificationComponent {
    hasNotification = 'hidden';
    isError: boolean;
    isInfo: boolean;
    isWarning: boolean;
    notificationMessage: string;

    constructor(private na: NotificationAggregator, private translateService: TranslateService) {
        this.na.subscribe((notification: Notification) => {
            this.setNotificationValues(notification);
        });
    }

    forceClose() {
        this.hasNotification = 'hidden';
    }

    private startNotification() {
        this.hasNotification = 'shown';
        setTimeout(() => { this.hasNotification = 'hidden'; }, 6000);
    }

    private setNotificationValues(notification: Notification) {
        if (!notification.flags.isConnectionRefused && !notification.flags.isNotFound && !notification.flags?.isInternal) {
            this.translateService.get(notification.notificationMessage, notification.localizationExtra).subscribe(res => {
                this.notificationMessage = res;
                this.isError = notification.flags.isError;
                this.isInfo = notification.flags.isInfo;
                this.isWarning = notification.flags.isWarning;

                this.startNotification();
            });
        }
    }
}
