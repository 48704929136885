import { SubscriberItem } from './SubscriberItem';
export class MemorandumReportParam {
    languageId:number;
    registerId:number;
    registerDesc:string;
    entityTypeId:string;
    entityTypeDesc: string ;
    entityRegDate:string;
    entityRegName: string;
    entityRegNumber: string;
    objectives: string;
    dissolution: number;
    geographicArea: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    postCode: string;
    hTMLTemplate:string;
    subscribers:SubscriberItem[]
}
