import { Component, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { RegsysHttp, LocalStorageService, GetRequest, PostRequest, SessionStorageService } from '@regsys/http';
import { SecurityEvent, CustomerUser, CustomerPrivileges, AuthService } from '@regsys/security';
import { environment } from '../../../environments/environment';

@Component({
    templateUrl: './login.component.html'
})
export class LoginComponent implements AfterViewInit, OnDestroy {

    public showGuest: boolean;
    private redirectUrl: string;
    hideSignupBtn: boolean;
    showError: boolean;
    errorMessage: string;
    isProduction: boolean = environment.production;
    refreshSubscription: any;
    constructor(private router: Router, @Inject(DOCUMENT) private document: Document,
        private route: ActivatedRoute,
        private httpService: RegsysHttp,
        private localStorageService: LocalStorageService,
        private securityEvent: SecurityEvent,
        private sessionStorageService: SessionStorageService) {
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnInit() {
        this.redirectUrl = '';
        this.route.params.forEach((params: Params) => {
            this.showGuest = params.ag;
            this.redirectUrl = params.rd_url;
        });
        this.localStorageService.SetValue('redirectUrl', this.redirectUrl);

        this.isProduction = environment.production;
        if (window.location.href.includes('localhost')) {
            this.isProduction = environment.production;
        } else {
            this.isProduction = environment.production;
        }
        this.route.queryParamMap.subscribe(params => {
            if (params.has('rpid')) {
                const rpid = `${params.get('rpid')}`;
                this.localStorageService.SetValue('rpid', rpid);
            }
            if (params.has('sessionID')) {
                const sessionID = `${params.get('sessionID')}`;
                this.localStorageService.SetValue('sessionID', sessionID);
            }

            if (params.has('nid')) {

                const userId = `${params.get('nid')}`;
                this.localStorageService.SetValue('nid', userId);
                this.isProduction = !environment.production;
                // const queryString = '?nid=' + params.get('nid')  ;
                const postRequest: PostRequest = {
                    url: `${environment.apiUrl}LobbyistSSO/complete`,
                    parameters: params.get('nid')
                };
                this.httpService.doPost(postRequest).subscribe((httpResponse: any) => {
                    const authData = httpResponse.data;
                    if (authData != null) {
                        this.localStorageService.SetValue('token', authData.authToken);
                        this.localStorageService.SetValue('refresh_token', authData.refreshToken);
                        const custUser: CustomerUser = new CustomerUser(authData.customerUserId, authData.customerUserName, authData.customerUserPreferredLanguageId, authData.customerTypeId);
                        this.localStorageService.SetValue('customer_user', custUser);
                        const custPrivileges: CustomerPrivileges = new CustomerPrivileges().fromResponse(authData.privileges);
                        this.localStorageService.SetValue('cust_privileges', custPrivileges);
                        this.sessionStorageService.RemoveValue('isGuest');
                        this.sessionStorageService.RemoveValue('isGuestFilingMode');
                        this.securityEvent.loggedIn.publish(true);

                        this.redirectUrl = this.localStorageService.GetStringValue('redirectUrl');
                        if (this.redirectUrl != '' && this.redirectUrl != undefined) {
                            this.router.navigate([this.redirectUrl]);
                        } else {
                            this.router.navigate(['dashboard']);
                        }
                    } else {
                        this.securityEvent.loggedIn.publish(false);
                        this.localStorageService.RemoveValue('token');
                        this.localStorageService.RemoveValue('refresh_token');
                        this.localStorageService.RemoveValue('customer_user');
                        this.localStorageService.RemoveValue('cust_privileges');
                        this.router.navigate(['/ssologout']);
                    }
                });
            } else if (params.has('error')) {
                // this.showError = true;
                // this.errorMessage = params.get('error');
            }
        });
    }

    // TODO: Find better way to set classes
    ngAfterViewInit() {
        this.document.body.classList.add('rs-background');
        this.document.querySelector('.rs-header').classList.add('rs-header-opacity');
        this.document.querySelector('.rs-main-wrapper').classList.add('rs-content-opacity');
        this.document.querySelector('.rs-leftpanel').classList.add('hidden');
        this.document.querySelector('.rs-leftpanel-toggler').classList.add('hidden');
        this.document.querySelector('.rs-search-input').classList.add('background-inherited');
    }

    ngOnDestroy() {
        this.document.body.classList.remove('rs-background');
        this.document.querySelector('.rs-header').classList.remove('rs-header-opacity');
        this.document.querySelector('.rs-main-wrapper').classList.remove('rs-content-opacity');
        this.document.querySelector('.rs-leftpanel').classList.remove('hidden');
        this.document.querySelector('.rs-leftpanel-toggler').classList.remove('hidden');
        this.document.querySelector('.rs-search-input').classList.remove('background-inherited');
    }

    onRequestPassword(event) {
        this.hideSignupBtn = event;
    }

    redirectToSignUp() {
        window.location.href = `${environment.apiUrl}LobbyistSSO/register`;
        // const getRequest: GetRequest = {
        //  url:`${environment.apiUrl}sso/register`
        // url: `http://localhost:34261/api/starter/complete-login/${params.get('nid')}`
        //  }
        // this.httpService.doGet(getRequest).subscribe(res => {
        //     console.log(res.data);
        //  });
        // Original Implementation
        // this.router.navigate(['/registration/sign-up']);
    }

    redirectToLogin() {
        // window.location.href = `${environment.apiUrl}SSO/Login/`+environment.apiKey;
        window.location.href = `${environment.apiUrl}LobbyistSSO/Login`;
    }

    successfullyLoggedInHandler(redirectEvent: string) {
        if (!environment.production) {
            this.router.navigate([redirectEvent || '/dashboard']);
        } else {
        this.redirectUrl = this.localStorageService.GetStringValue('redirectUrl');
        if (this.redirectUrl != '') {
            this.router.navigate([redirectEvent || this.redirectUrl]);
        } else {
            this.router.navigate([redirectEvent || '/dashboard']);
        }
    }
    }








}
