import { IsGovernmentCustomerTypeDirective } from "./directives/isGovernmentCustomerType.directive";
import { NgModule, APP_INITIALIZER, Injectable, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { JwtModule, JwtModuleOptions, JWT_OPTIONS } from '@auth0/angular-jwt';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as Hammer from 'hammerjs';

// NG Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';


import { BrowserDetectionService, LanguageInitializerService, TimezoneInitializerService, ErrorHandlerService, LoggingService } from '@regsys/common';

import { RegsysLoader } from './regsys.module';
import { RegsysRoutingModule } from './app-routing.module';
import { MaterialModule } from './models/material.module';

import { environment } from '../environments/environment';
import * as fromContainers from './containers';
import * as fromComponents from './components';
import { RegSysPaginatorIntlService } from './services/regsys-paginator-intl.service';
import { WindowResizeService } from './services/window-resize.service';
import { HomeSearchService } from './services/home-search.service';
import { PersonSearchService } from './services/person-search.service';
import { EntityService } from "./services/entity.service";



@Injectable()
export class HammerConfig extends HammerGestureConfig {
    buildHammer(element: HTMLElement) {
      let options = {};
      if (element.attributes['data-mc-options']) {
        try {
          const parseOptions = JSON.parse(element.attributes['data-mc-options'].nodeValue);
          options = parseOptions;
        } catch (err) {
          console.error('An error occurred when attempting to parse Hammer.js options: ', err);
        }
      }
  
      const mc = new Hammer(element, options);
  
      // keep default angular config
      mc.get('pinch').set({ enable: true });
      mc.get('rotate').set({ enable: true });
  
      // retain support for angular overrides object
      // tslint:disable-next-line:forin
      for (const eventName in this.overrides) {
        mc.get(eventName).set(this.overrides[eventName]);
      }
  
      return mc;
    }
  }

const jwtConf: JwtModuleOptions = {
    jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: []
    }
};

const MY_DATE_FORMATS = {
  parse: {
    dateInput: environment.ui.date.format
  },
  display: {
    dateInput: environment.ui.date.format,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: environment.ui.date.format,
    monthYearA11yLabel: 'MMM YYYY',
  },
};

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgbModule,
        JwtModule.forRoot(jwtConf),
        MaterialModule,
        RegsysRoutingModule,
        RegsysLoader,
        TranslateModule.forRoot(),
        StoreModule.forRoot({}, {
          runtimeChecks: {
            strictStateImmutability: false,
            strictActionImmutability: false
          }
        }),
        EffectsModule.forRoot([])
    ],
    exports: [
      IsGovernmentCustomerTypeDirective
    ],
    declarations: [
      ...fromContainers.containers,
      ...fromComponents.components,
      IsGovernmentCustomerTypeDirective
  ],
    providers: [
    BrowserDetectionService,
    HomeSearchService,
    PersonSearchService,
    EntityService,
    WindowResizeService,
    {
      provide: APP_INITIALIZER,
      useFactory: initLocalization,
      deps: [LanguageInitializerService, TimezoneInitializerService, LoggingService ],
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: MatPaginatorIntl, useClass: RegSysPaginatorIntlService },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    { provide: MAT_DATE_LOCALE, useValue: environment.ui.defaultLanguage },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
    ],
    bootstrap: [fromContainers.AppComponent]
})
export class AppModule { }

export function initLocalization(
    languageInitializerService: LanguageInitializerService,
    timezoneInitializerService: TimezoneInitializerService
    ,
    loggingService: LoggingService
): () => Promise<any> {

     const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: environment.appInsightsSettings.instrumentationKey,
            enableAutoRouteTracking: false
        }
    });
     loggingService.appInsights = appInsights.loadAppInsights();
    
    const result = () => languageInitializerService.initializeLocalization().then(() => {
        timezoneInitializerService.initializeTimezone();
    });

    return result;
}


export function tokenGetter() {
    return localStorage.getItem('token') || '';
}

export function jwtOptionsFactory() {
    return {
        tokenGetter: tokenGetter,
        whitelistedDomains: environment.whitelistDomains, // To disable: [new RegExp('[\s\S]*')] as RegExp[]
        throwNoTokenError: false
    };
}
