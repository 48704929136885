
<div class="rs-login-component" *ngIf="false">
  <div class="rs-login-form-wrapper">
    <regsys-login (requestPassword)="onRequestPassword($event)" (successfullyLoggedIn)="successfullyLoggedInHandler($event)"></regsys-login>
    <div class="rs-new-client" *ngIf="!hideSignupBtn">
      <h1>{{ 'loginComponent.newClient' | translate }}</h1>
      <button class="create-account-btn" type="button" id="createAccount" protract="createAccountBtn" (click)="redirectToSignUp()">
        {{ 'loginComponent.createAccount' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="rs-login-component" *ngIf="true">
  <div class="rs-login-form-wrapper">
    <!--<regsys-login (requestPassword)="onRequestPassword($event)" (successfullyLoggedIn)="successfullyLoggedInHandler($event)"></regsys-login>-->
    <div *ngIf="showError" aria-describedby="validation_message" aria-live="assertive" class="alert alert-danger ng-star-inserted" role="alertdialog" aria-label="notification">
      <span id="validation_message">{{errorMessage}}</span>
    </div>
    <div class="rs-new-client" *ngIf="!hideSignupBtn">
      <h1>{{ 'loginComponent.registerHere' | translate }}</h1>
      <div class="rs-login-submit">
      <button class="rs-button btn btn-outline-primary rs-login-submit" type="button" id="LoginCreateAccount" protract="createAccountBtn" (click)="redirectToLogin()">
        {{ 'loginComponent.LoginCreateAccount' | translate }}
      </button>
    </div>

    </div>
  </div>
</div>
