
import { SSOLogoutComponent } from './containers/ssologout/ssologout.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PendingChangesGuard } from '@regsys/common';
import { AnonymousAccessGuard, AuthGuard, HasPrivilegeGuard, ResetPasswordComponent } from '@regsys/security';
import {
    AccountComponent,
    AdvancedSearchComponent,
    CatalogComponent,
    CheckoutComponent,
    ConfirmUserComponent,
    FormsFilingComponent,
    FormsHistoryComponent,
    MessagesComponent,
    OrderDetailsComponent,
    OrdersComponent,
    PersonDetailsComponent,
    SignUpComponent,
    TransactionComponent,
    UserProfileComponent,
    AccountTopUpComponent,
    WatchlistComponent
} from '@regsys/web';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { FourOFourComponent } from './containers/404/404.component';
import { HomeComponent } from './containers/home/home.component';
import { LoginComponent } from './containers/login/login.component';
import { UnknownErrorComponent } from './containers/unknown-error/unknown-error.component';

import { PersonSearchComponent } from './containers/person-search/person-search.component';
import { LobCatalogComponent } from './containers/catalog/lob.catalog.component';
export const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        children: [
            { path: '', component: DashboardComponent },
            { path: ':lang', component: DashboardComponent }
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'ssologout',
        component: SSOLogoutComponent
    },
    {
        path: 'home',
        component: HomeComponent,
    },
    {
        path: 'reset-password/:code',
        component: ResetPasswordComponent
    },
    {
        path: 'registration',
        children: [
            { path: 'sign-up', component: SignUpComponent },
            { path: 'confirm/:token', component: ConfirmUserComponent }
        ]
    },
    {
        path: 'form',
        canActivate: [AuthGuard, HasPrivilegeGuard],
        data: { privileges: ['CanCreateSubmission'] },
        children: [
            { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
            { path: ':typeId', component: FormsFilingComponent, canDeactivate: [PendingChangesGuard], runGuardsAndResolvers: 'always' },
            { path: ':typeId/:id', component: FormsFilingComponent, canDeactivate: [PendingChangesGuard], runGuardsAndResolvers: 'always' },
            { path: 'draft/:id', component: FormsFilingComponent, canDeactivate: [PendingChangesGuard] },
            { path: 'return/:id', component: FormsFilingComponent, canDeactivate: [PendingChangesGuard] }
        ]
    },
    { path: 'digital-signatures/:code', component: FormsFilingComponent, canActivate: [AnonymousAccessGuard] },
    { path: 'submission-payment-guest/:code', component: FormsFilingComponent, canActivate: [AnonymousAccessGuard] },
    { path: 'submission-complete-payment/:tranRefNum/:draftId/:responseHash/:mode', component: FormsFilingComponent },
    {
        path: 'filings',
        canActivate: [AuthGuard, HasPrivilegeGuard],
        children: [
            { path: '', component: FormsHistoryComponent },
            { path: 'tab/:tabId', component: FormsHistoryComponent }
        ]
    },
    {
        path: 'transactions',
        component: TransactionComponent,
        canActivate: [AuthGuard, HasPrivilegeGuard],
        data: { privileges: ['CanViewTrans'] }
    },
    {
        path: 'search',
        component: AdvancedSearchComponent
    },
    {
        path: 'person-search',
        canActivate: [AuthGuard, HasPrivilegeGuard],
        data: { privileges: ['Administrator'] },
        // loadChildren: '../app.extension/advanced-person-search/person-search.module#PersonSearchModule'
         component: PersonSearchComponent

    },
    {
        path: 'user-profile',
        canActivate: [AuthGuard],
        children: [
            { path: '', component: UserProfileComponent }
        ]
    },
    {
        path: 'account',
        canActivate: [AuthGuard, HasPrivilegeGuard],
        data: { privileges: ['Administrator'] },
        children: [
            { path: '', component: AccountComponent },
            { path: 'tab/:tabId', component: AccountComponent },
            { path: 'user-profile/:id', component: UserProfileComponent },
            { path: 'new-user', component: UserProfileComponent, data: { isNew: true } }
        ]
    },
    {
        path: 'messages',
        canActivate: [AuthGuard],
        children: [
            {
                path: '', component: MessagesComponent,
                canDeactivate: [PendingChangesGuard]
            },
            {
                path: 'tab/:tabId', component: MessagesComponent,
                canDeactivate: [PendingChangesGuard]
            },
            {
                path: 'tab/:tabId/:messageId', component: MessagesComponent,
                canDeactivate: [PendingChangesGuard]
            },
        ]
    },
    {
        path: 'e-commerce',
        children: [
            { path: 'company/:id', component: LobCatalogComponent },
            { path: 'company/:search/:id', component: LobCatalogComponent }
        ]
    },
    {
        path: 'checkout',
        children: [
            { path: '', component: CheckoutComponent },
            { path: ':id/:responseHash/:mode', component: CheckoutComponent }
        ]
    },
    {
        path: 'orders',
        canActivate: [AuthGuard],
        children: [
            { path: '', component: OrdersComponent },
            { path: 'tab/:tabId', component: OrdersComponent },
            { path: ':id', component: OrderDetailsComponent }
        ]
    },
    {
		path: 'account-top-up',
		children: [
			{
				path: '',
				component: AccountTopUpComponent
			},
			{
				path: ':id/:responseHash/:mode',
				component: AccountTopUpComponent
			}
		],
		canActivate: [AuthGuard, HasPrivilegeGuard],
		data: { privileges: ['CanChargeAccount'] }
	},
    { path: 'persons/:id', component: PersonDetailsComponent },
    { path: 'watchlist', component: WatchlistComponent, canActivate: [AuthGuard] },
    { path: 'unknown-error/:statusCode', component: UnknownErrorComponent },
    { path: '404', component: FourOFourComponent },
    { path: '**', redirectTo: '404' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule],
    providers: []
})
export class RegsysRoutingModule { }
