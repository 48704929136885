import { Component, Output, EventEmitter, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'regsys-header',
  templateUrl: './app-header.component.html'
})
export class HeaderComponent {
  @Input() isLeftPanelCollapsed: boolean;
  @Output() leftPanelChanged: EventEmitter<boolean> = new EventEmitter();
  isUserMenuCollapsed = true;
  isSearchCollapsed = true;

  constructor() {}

  toggleLeftPanel(): void {
    this.isLeftPanelCollapsed = !this.isLeftPanelCollapsed;
    this.leftPanelChanged.emit(this.isLeftPanelCollapsed);
  }

  toggleUserMenu(): void {
    this.isUserMenuCollapsed = !this.isUserMenuCollapsed;
  }

  quickSearchChangedHandler(isSearchCollapsed: boolean): void {
    this.isSearchCollapsed = isSearchCollapsed;
  }

  redirectToLogin() {
    if (environment.production) {
      window.location.href = `${environment.apiUrl}LobbyistSSO/Login`;
    } else {
      window.location.href = `/login`;
    }
  }
}
