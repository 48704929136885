<div class="rs-global-content-wrapper">
    <div class="rs-advanced-search-container rs-sticky-container" @rsFadeAnimation>
        <div class="row">
            <div class="col-lg-4">
                <aside class="rs-form-wrapper">
                    <h1 class="rs-h1 mb-3 mb-xl-4">{{ 'PersonSearchComponent.search' | translate }}</h1>
                    <regsys-search-by-person [isSearching]="isSearching"
                        (notificationMessage)="handleNotification($event)" (clearResults)="reset()"
                        (searchRequest)="doSearch($event)"></regsys-search-by-person>
  
                </aside>
            </div>
            <div class="col-lg-8">
                <regsys-search-results-grid *ngIf="searchResult" [searchResult]="searchResult"></regsys-search-results-grid>
            </div>
        </div>
    </div>
</div>