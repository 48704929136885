import { Sort } from '@angular/material/sort';
import moment from 'moment';
import orderBy from 'lodash-es/orderBy';
import some from 'lodash-es/some';

// Functions
import { SortGridDates } from './sort-grid-dates.function';

export function SortGrid(sortEvent: Sort, collectionToSort: any[], formatting: any) {
    const format = sortEvent.active;
    const dateFormat = formatting.dateFormat;
    const dateTimeFormat = formatting.dateTimeFormat;
    const formatUsed = format === 'datetime' ? dateTimeFormat : dateFormat;

    const stringDateFormatted = some(collectionToSort, p => moment(p[sortEvent.active], formatUsed, true).isValid());
    // If the column to be sorted is a date that was formatted into a string
    if (stringDateFormatted === true) {
        return SortGridDates(collectionToSort, formatUsed, sortEvent.active, sortEvent.direction);
    }
    return orderBy(collectionToSort, sortEvent.active, sortEvent.direction || true);
}
