<section class="search rs-home-image rs-home-background">
    <div class="search-wrapper" [class.results-visible]="(searchResponse?.length > 0 || isEmptyResult) && !hideResults">
        <h1 class="rs-h1 mb-3 mb-md-4">{{ 'publicSearch.title' | translate }}</h1>
        <div class="search-input">
            <input type="text" [formControl]="quickterm" [placeholder]="'publicSearch.searchPlaceholder' | translate">
            <div *ngIf="isSearching" class="rs-searching-icon" [attr.aria-label]="'publicSearch.searching' | translate">
                <span class="searching-icon rs-loading"></span>
                <span class="searching-text">{{ 'publicSearch.searching' | translate }}</span>
            </div>
           
        </div>
        <br/>
        <h1 class="rs-h2 mb-md-4 mb-md-4">{{ 'publicSearch.advancedSearch' | translate }} <a class="rs-h2 mb-md-4 mb-md-4" href='search' style="color: white;" dir='_self'>{{ 'publicSearch.here' | translate }}</a> </h1>
        <i class="rs-arrow rs-arrow-primary d-none d-md-inline-block"></i>
    </div>
</section>
<section @rsFadeAnimation class="result-search p-3 p-md-0" *ngIf="(searchResponse?.length > 0 || isEmptyResult) && !hideResults">
    <div class="results-wrapper pb-4">
        <div *ngIf="isEmptyResult" class="results-message pt-4">
            <span>{{ 'publicSearch.noResults' | translate }}</span>
        </div>

        <div class="results-button mb-3 mb-md-0" *ngIf="!isEmptyResult">
            <button class="rs-button rs-btn-icon-only btn btn-outline-primary float-right" type="button" [title]="" (click)="onHideResults()">

                <span class="rs-button-icon rs-cross-blue"></span>
            </button>
        </div>

        <ng-container *ngIf="searchResponse?.length > 0">
            <mat-table [dataSource]="dataSource" [@rsListAnimation]='searchResponse.length' [attr.aria-label]="" matSort>

                <ng-container matColumnDef="registeredNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'publicSearch.entityRegNum' | translate }}</mat-header-cell>
                    <mat-cell class="d-block d-lg-inline-block flex-lg-row mx-2 mb-2 m-lg-0" *matCellDef="let row" [attr.aria-label]="row.registeredNumber">
                        <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">{{ 'publicSearch.entityRegNum' | translate }}</h3>
                        <p class="cell-content d-inline-block d-lg-block pr-lg-2">{{row.registeredNumber}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="registeredName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'publicSearch.entityRegName' | translate }}</mat-header-cell>
                    <mat-cell class="d-block d-lg-inline-block flex-lg-row mx-2 mb-2 m-lg-0" *matCellDef="let row" [attr.aria-label]="row.registeredName">
                        <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">{{ 'publicSearch.entityRegName' | translate }}</h3>
                        <p class="cell-content d-inline-block d-lg-block pr-lg-2">{{row.registeredName}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="entityTypeDesc">
                    <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
                    <mat-cell class="d-block d-lg-inline-block flex-lg-row mx-2 mb-2 m-lg-0" *matCellDef="let row" [attr.aria-label]="row.entityTypeDesc">
                        <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">{{ 'publicSearch.entityTypeDesc' | translate }}</h3>
                        <p class="cell-content d-inline-block d-lg-block pr-lg-2">{{row.entityTypeDesc}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="entityId">
                    <mat-header-cell *matHeaderCellDef>{{ 'publicSearch.view' | translate }}</mat-header-cell>
                    <mat-cell class="buttons-cell d-block d-inline-block flex-lg-row flex-lg-grow-0 flex-lg-shrink-0" *matCellDef="let row" [attr.aria-label]="">
                        <regsys-reactive-button iconClass="rs-open" (click)="onClick(row.entityId)" [buttonText]="'publicSearch.view'" [title]="'publicSearch.view'"></regsys-reactive-button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row class="d-block d-lg-flex flex-lg-row p-2 py-3 mt-2 p-lg-3 mt-lg-3" *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <mat-paginator [class.hidden]="searchResponse.length < 5" [length]="searchResponse.length" [pageIndex]="0" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </ng-container>
    </div>
</section>