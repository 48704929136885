import { Component, Input, ViewChild, SimpleChange, OnChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { RegSysDataSource, RegsysWebConfig } from '@regsys/web';
import { SortGrid } from '../../../shared/functions/sort/src/sort-grid.function';
import { privateFunding } from '../../../../models/private-funding.model';



@Component({
    selector: 'regsys-private-funding',
    templateUrl: './entity-private-funding.component.html'
})
export class EntityPrivateFundingComponent implements OnChanges {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) gridSort: MatSort;

    @Input() privateFundings: privateFunding[];

    gridprivateFundings: privateFunding[];
    displayedColumns = ['privateFundingName', 'businessAddress'];
    dataSource: RegSysDataSource;

    private formatting: any;

    constructor(regsysConfig: RegsysWebConfig) {
        this.formatting = regsysConfig.config.formattingOptions;
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if (changes.privateFundings) {
            if (this.paginator) {
                this.paginator.pageIndex = 0;
            }

            this.gridprivateFundings = SortGrid({ active: 'attributeItemId', direction: 'asc' }, this.privateFundings, this.formatting);
            this.dataSource = new RegSysDataSource(this.gridprivateFundings, this.paginator, this.gridSort);
        }
    }

    handlePaging(event: any) {
        // TODO: Find a better way to do this
        this.dataSource = new RegSysDataSource(this.gridprivateFundings, this.paginator, this.gridSort);
    }
}
