<dl class="mt-2 rs-entity-addresses">
    <h5 class="py-3 px-4">{{ 'forms.lobbyist.labels.clientDetails' | translate }}</h5>
    <ng-container *ngIf="entity.personInd; else companyDetails">
        <div class="rs-entity-name rs-display-block" *ngIf="entity.displayName">
            <dt class="rs-field-label">{{ 'personDetailsComponent.details.regName' | translate }}</dt>
            <dd class="rs-regular-text" replaceEmpty>{{entity.displayName}}</dd>
        </div>
        <div class="rs-entity-registration-date rs-display-block" *ngif="entity.effectiveDate">
            <dt class="rs-field-label">{{ 'companyRelationshipsGridComponent.effectiveDateDesc' | translate }}</dt>
            <dd class="rs-regular-text" replaceEmpty>{{entity.effectiveDate | localDate}}</dd>
        </div>
    </ng-container>

    <ng-template #companyDetails>
        <div class="rs-entity-number rs-display-block">
            <dt class="rs-field-label">{{ 'companyDetailsComponent.regNumber' | translate }}</dt>
            <dd class="rs-regular-text" replaceEmpty>{{entity.entityRegNumber}}</dd>
        </div>
        <div class="rs-entity-name rs-display-block">
            <dt class="rs-field-label">{{ 'companyDetailsComponent.regName' | translate }}</dt>
            <dd class="rs-regular-text" replaceEmpty>{{entity.entityRegName}}</dd>
        </div>
        <div class="rs-entity-type rs-display-block">
            <dt class="rs-field-label">{{ 'companyDetailsComponent.regAddress' | translate }}</dt>
            <dd class="rs-regular-text" replaceEmpty>{{entity.physicalAddress}}</dd>
        </div>
        
    </ng-template>
</dl>