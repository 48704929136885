<div *ngIf="searchResult?.results?.length > 0"  class="mat-table-container">
    <div class="result-options">
        <regsys-reactive-button iconClass="rs-download" (click)="downloadAsCsv()"
            [buttonText]="'PersonSearchComponent.downloadAsCsv'" [title]="'PersonSearchComponent.downloadAsCsv' | translate"
            [attr.aria-label]="'PersonSearchComponent.downloadAsCsv' | translate"></regsys-reactive-button>
        <p class="pt-2" *ngIf="searchResult.searchDate">{{ 'PersonSearchComponent.searchDate' | translate }}: {{searchResult.searchDate | date: 'medium'}}</p>
    </div>
    <mat-table [dataSource]="dataSource" class="p-2" aria-label="person Search Table" [@rsListAnimation]="searchResult?.results?.length"
        matSort>
        <ng-container matColumnDef="EntityRegName">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell class="d-block d-lg-inline-block flex-lg-row px-2 mb-2 m-lg-0" *matCellDef="let row"
                [attr.data-label]="'PersonSearchComponent.entityRegName' | translate"
                [attr.aria-label]="row.entityRegName">
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{'PersonSearchComponent.entityRegName' | translate}}
                </h3>
                <p class="cell-content d-inline-block d-lg-block pr-lg-2" replaceEmpty>
                    {{row.entityRegName}}
                </p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="RegAddressOneLine">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell class="d-block d-lg-inline-block flex-lg-row px-2 mb-2 m-lg-0" *matCellDef="let row"
                [attr.data-label]="'PersonSearchComponent.RegAddressOneLine' | translate"
                [attr.aria-label]="row.RegAddressOneLine">
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{'PersonSearchComponent.RegAddressOneLine' | translate}}
                </h3>
                <p class="cell-content d-inline-block d-lg-block pr-lg-2" replaceEmpty>
                    {{row.regAddressOneLine}}
                </p>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="ParentEntityRegNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell class="d-block d-lg-inline-block flex-lg-row px-2 mb-2 m-lg-0" *matCellDef="let row"
                [attr.data-label]="'PersonSearchComponent.parentEntityRegNumber' | translate"
                [attr.aria-label]="row.parentEntityRegNumber">
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{'PersonSearchComponent.parentEntityRegNumber' | translate}}
                </h3>
                <p class="cell-content d-inline-block d-lg-block pr-lg-2" replaceEmpty>
                    {{row.parentEntityRegNumber}}
                </p>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ParentEntityRegName">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell class="d-block d-lg-inline-block flex-lg-row px-2 mb-2 m-lg-0" *matCellDef="let row"
                [attr.data-label]="'PersonSearchComponent.parentEntityRegName' | translate"
                [attr.aria-label]="row.parentEntityRegName">
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{'PersonSearchComponent.parentEntityRegName' | translate}}
                </h3>
                <p class="cell-content d-inline-block d-lg-block pr-lg-2" replaceEmpty>
                    {{row.parentEntityRegName}}
                </p>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="ParentCivicAddressOneLine">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell class="d-block d-lg-inline-block flex-lg-row px-2 mb-2 m-lg-0" *matCellDef="let row"
                [attr.data-label]="'PersonSearchComponent.parentCivicAddressOneLine' | translate"
                [attr.aria-label]="row.parentCivicAddressOneLine">
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{'PersonSearchComponent.parentCivicAddressOneLine' | translate}}
                </h3>
                <p class="cell-content d-inline-block d-lg-block pr-lg-2" replaceEmpty>
                    {{row.parentCivicAddressOneLine}}
                </p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="RelationshipTypeDesc">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell class="d-block d-lg-inline-block flex-lg-row px-2 mb-2 m-lg-0" *matCellDef="let row"
                [attr.data-label]="'PersonSearchComponent.relationshipTypeDesc' | translate"
                [attr.aria-label]="row.relationshipTypeDesc">
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{'PersonSearchComponent.relationshipTypeDesc' | translate}}
                </h3>
                <p class="cell-content d-inline-block d-lg-block pr-lg-2" replaceEmpty>
                    {{row.relationshipTypeDesc}}
                </p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="RelationshipStartDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell class="d-block d-lg-inline-block flex-lg-row px-2 mb-2 m-lg-0" *matCellDef="let row"
                [attr.data-label]="'listFilterComponent.startDate' | translate"
                [attr.aria-label]="row.relationshipStartDate">
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{'listFilterComponent.startDate' | translate}}
                </h3>
                <p class="cell-content d-inline-block d-lg-block pr-lg-2" replaceEmpty>
                    {{row.relationshipStartDate | date: 'dd/MM/yyyy'}}
                </p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="RelationshipEndDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell class="d-block d-lg-inline-block flex-lg-row px-2 mb-2 m-lg-0" *matCellDef="let row"
                [attr.data-label]="'listFilterComponent.endDate' | translate"
                [attr.aria-label]="row.relationshipEndDate">
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{'listFilterComponent.endDate' | translate}}
                </h3>
                <p class="cell-content d-inline-block d-lg-block pr-lg-2" replaceEmpty>
                    {{row.relationshipEndDate | date:'dd/MM/yyyy'}}
                </p>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ParentEntityStatusDesc">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell class="d-block d-lg-inline-block flex-lg-row px-2 mb-2 m-lg-0" *matCellDef="let row"
                [attr.data-label]="'forms.rjsc.entity.entityStatus' | translate"
                [attr.aria-label]="row.parentEntityStatusDesc">
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{'forms.rjsc.entity.entityStatus' | translate}}
                </h3>
                <p class="cell-content d-inline-block d-lg-block pr-lg-2" replaceEmpty>
                    {{row.parentEntityStatusDesc}}
                </p>
            </mat-cell>
        </ng-container>



        <ng-container matColumnDef="entityId">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell class="buttons-cell d-block d-inline-block flex-lg-row flex-lg-grow-0 flex-lg-shrink-0"
                *matCellDef="let row" [attr.aria-label]="'Profile for ' + row.financialStatementNumber">
                <regsys-reactive-button iconClass="rs-open" (click)="onRedirect(row.parentEntityId)" [buttonText]="'View'"
                    [title]="'View Profile for ' + row.parentEntityRegName"
                    [attr.aria-label]="'View Profile for ' + row.parentEntityRegName"></regsys-reactive-button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="d-block d-lg-flex align-items-start flex-lg-row p-2 py-3 mt-2 p-lg-3 mt-lg-3"
            *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>

<mat-paginator class="mt-2 mt-lg-3" [class.hidden]="searchResult?.results?.length < 10" [length]="searchResult?.results?.length"
    [pageIndex]="0" [pageSizeOptions]="[10, 25, 50]">
</mat-paginator>

<section *ngIf="searchResult?.results?.length === 0" class="rs-filtered-content rs-search-result">
    <div class="rs-search-result-empty" role="alert">
        <i class="ion-alert-circled"></i>
        <p class="title">{{ 'PersonSearchComponent.noResults' | translate }}</p>
    </div>
</section>