<div *ngIf="entity; else loadingEntity" class="e-commerce-catalog rs-global-content-wrapper">
    <div class="h1-button-container mb-3">
        <h1 class="rs-h1">{{entity.name }}</h1>
    </div>
    <div class="e-commerce-cust-actions row" *ngIf="isShowingCustomActions">
        <regsys-ecommerce-custom-actions [entity]="entity" class="col-lg-12"></regsys-ecommerce-custom-actions>
    </div>
    <div class="e-commerce-profile row">
        <div class="col-sm-12 col-lg-8" [class.d-none]="!displayProductsTab">
            <regsys-products-tabs [entity]="entity" (displayProductsTab)="displayProductsTabHandler($event)"
                                  class="rs-products-tabs"></regsys-products-tabs>
        </div>
        <div class="col-sm-12 col-lg-4" [class.col-lg-8]="!displayProductsTab">
            <div class="rs-container rs-content rs-container-border pt-lg-2">
                <div class="rs-tabset-wrapper">
                    <ngb-tabset class="ngb-tabset">
                        <ngb-tab [title]="'eCommerce.entity.companyProfile' | translate"
                                 id="tab-catalog-entity-profile">
                            <ng-template ngbTabContent>
                                <regsys-catalog-entity-details [entity]="entity"></regsys-catalog-entity-details>
                                <regsys-entity-addresses [entity]="entity" [otherAddresses]="entityOtherAddresses" *ngIf="entityOtherAddresses"></regsys-entity-addresses>
                                <regsys-entity-employer-details *ngIf="lobbyistEmployer" [entity]="lobbyistEmployer"></regsys-entity-employer-details>
                                <regsys-entity-client-details *ngIf="lobbyistClient" [entity]="lobbyistClient"></regsys-entity-client-details>
                                
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab *ngIf="entityNames && entityNames.length > 0" id="tab-catalog-entity-names">
                            <ng-template ngbTabTitle>
                                {{'eCommerce.entity.names' | translate}}
                            </ng-template>
                            <ng-template ngbTabContent>
                                <regsys-entity-names class="rs-names" [names]="entityNames"></regsys-entity-names>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab *ngIf="entityRelationships && entityRelationships.length > 0"
                                 id="tab-catalog-relationships" [title]="'eCommerce.entity.relationships' | translate">
                            <ng-template ngbTabContent>
                                <regsys-entity-relationships [relationships]="entityRelationships">
                                </regsys-entity-relationships>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab *ngIf="entityLatestEvents && entityLatestEvents.length > 0"
                                 id="tab-catalog-latest-events">
                            <ng-template ngbTabTitle>
                                {{'eCommerce.entity.latestEvents' | translate}}
                                ({{entityLatestEvents.length}})
                            </ng-template>
                            <ng-template ngbTabContent>
                                <regsys-events-list class="rs-events" [eventsList]="entityLatestEvents"
                                                    [noDataMessage]="'CatalogComponent.eventsList.noData'"></regsys-events-list>
                            </ng-template>
                        </ngb-tab>

                        <ngb-tab *ngIf="lobbyingActivities && lobbyingActivities.length > 0"
                                 id="tab-catalog-lobbying-activity" [title]="'forms.lobbyist.tabLabel.lobbyingActivities' | translate">
                            <ng-template ngbTabContent>
                                <regsys-lobbying-activity [lobbyingActivities]="lobbyingActivities" [hideActivityPeriod] = "hideActivityPeriod">
                                </regsys-lobbying-activity>
                            </ng-template>
                        </ngb-tab>

                        <ngb-tab *ngIf="(governmentFundings && governmentFundings.length > 0) || (privateFundings && privateFundings.length > 0) "
                                 id="tab-catalog-government-funding" [title]="'forms.lobbyist.tabLabel.funding' | translate">
                            <ng-template ngbTabContent>
                                <dl class="mt-2 rs-entity-addresses">
                                    <h5 class="py-3 px-4">{{ 'forms.lobbyist.tabLabel.governmentFunding' | translate }}</h5>
                                </dl>
                                <regsys-government-funding [governmentFundings]="governmentFundings">
                                </regsys-government-funding>

                                <dl class="mt-2 rs-entity-addresses">
                                    <h5 class="py-3 px-4">{{ 'forms.lobbyist.tabLabel.privateFunding' | translate }}</h5>
                                </dl>
                                <regsys-private-funding [privateFundings]="privateFundings">
                                </regsys-private-funding>
                            </ng-template>
                        </ngb-tab>



                    </ngb-tabset>


                </div>
            </div>
        </div>
    </div>

    <ng-template regsys-customization></ng-template>
</div>

<ng-template #loadingEntity>
    <regsys-loading [styles]="['m-5']" [message]="'eCommerce.entity.loading'"></regsys-loading>
</ng-template>