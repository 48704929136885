import { Component, AfterViewInit, Inject, OnDestroy, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  templateUrl: './home.component.html'
})
export class HomeComponent implements AfterViewInit, OnDestroy {

  public images: String[] = [
    '../../../assets/images/partners/regsys_one.png',
    '../../../assets/images/partners/erslogo.png'
  ];

  public activeDot = 'search';

  private rsMinDesktopScreenWidth = 1200;
  private panel: Element;
  private content: Element;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngAfterViewInit() {
    this.document.querySelector('.rs-header').classList.add('rs-header-opacity');
    this.document.querySelector('.rs-header').classList.add('rs-header-home');
    this.panel = this.document.querySelector('.rs-leftpanel');
    this.content = this.document.querySelector('.rs-main-wrapper');
    this.handleWidth(document.body.offsetWidth);
  }

  ngOnDestroy() {
    this.document.querySelector('.rs-header').classList.remove('rs-header-opacity');
    this.document.querySelector('.rs-header').classList.remove('rs-header-home');
    this.undoOverrideGlobalClasses();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleWidth(event.target.innerWidth);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const searchRect = this.document.getElementById('search').getBoundingClientRect();
    const servicesRect = this.document.getElementById('services').getBoundingClientRect();
    const partnersRect = this.document.getElementById('partners').getBoundingClientRect();

    if (this.rectCheck(searchRect, 'search')) { return; }
    if (this.rectCheck(servicesRect, 'services')) { return; }
    if (this.rectCheck(partnersRect, 'partners')) { return; }
  }

  // TODO: performance improvements required
  private rectCheck(elementRect: any, elementId: string): boolean {
    if (elementRect.height / 2 > Math.abs(elementRect.top)) {
      this.activeDot = elementId;
      return true;
    }
    return false;
  }

  private handleWidth(screenWidth): void {
    screenWidth >= this.rsMinDesktopScreenWidth ?
      this.overrideGlobalClasses() : this.undoOverrideGlobalClasses();
  }

  private overrideGlobalClasses(): void {
    this.panel.classList.add('navpanel-home');
    this.content.classList.add('main-wrapper-home');
  }

  private undoOverrideGlobalClasses(): void {
    this.panel.classList.remove('navpanel-home');
    this.content.classList.remove('main-wrapper-home');
  }
}
