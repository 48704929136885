<div class="rs-home">

    <regsys-public-search id="search"></regsys-public-search>

    <section class="services" id="services">

        <ul class="rs-cards">

            <li class="rs-card">
                <div class="icon-block">
                    <table width='100%'>
                        <tr>
                            <td>
                                <h3 class="rs-h3">{{ 'forms.lobbyist.homeComponent.servicesInfoCard2Title' | translate }}</h3>

                            </td>
                            <td align="right" style="min-width: 150px;">
                                <button class="rs-button btn btn-outline-primary" onclick="window.location.href = 'login';">
                                    <span class="button-name">
                                        {{ 'homeComponent.startNow' | translate }}
                                    </span>
                                </button>
                            </td>
                        </tr>
                    </table>

                </div>

            </li>

            <li class="rs-card">
                <div class="icon-block">

                    <table width='100%'>
                        <tr>
                            <td>
                                <h3 class="rs-h3">{{ 'forms.lobbyist.homeComponent.servicesInfoCard3Title' | translate }}</h3>

                            </td>
                            <td align="right" style="min-width: 150px;">
                                <button class="rs-button btn btn-outline-primary" onclick="window.open('https://beta.novascotia.ca/programs-and-services/registry-lobbyists');">
                                    <span class="button-name">
                                        {{ 'homeComponent.learnmore' | translate }}
                                    </span>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>

            </li>

        </ul>
    </section>




    <div class="rs-dotsnav d-none d-md-block">
        <ul>
            <li><a [class.active]="activeDot === 'search'" href="/#search" [ngbTooltip]="'homeComponent.sideBullet.search' | translate" placement="left"></a></li>
            <li><a [class.active]="activeDot === 'services'" href="/#services" [ngbTooltip]="'homeComponent.sideBullet.services' | translate" placement="left"></a></li>

        </ul>
    </div>


</div>
