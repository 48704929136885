import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { StartNewFilingPanelService } from '@regsys/web';
import { WindowResizeService } from '@regsys/common';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'regsys-nav',
  templateUrl: 'app-nav.component.html'
})
export class AppNavComponent implements OnInit, OnDestroy {

  @Input() isLeftPanelCollapsed: boolean;
  @Output() leftPanelChanged: EventEmitter<boolean> = new EventEmitter();

  isOnForms: boolean;
  version = environment.version;

  private currentWindowInnerWidth: number;
  private desktopScreenWidthBreakpoint: number;
  private windowResizeSubscription: Subscription;
  private routerNavStartSubscription: Subscription;
  private routerNavEndSubscription: Subscription;

  constructor(
    public startNewFilingPanelService: StartNewFilingPanelService,
    private router: Router,
    private windowResizeService: WindowResizeService,
    @Inject(DOCUMENT) private document: Document) {

      this.desktopScreenWidthBreakpoint = environment.screenWidthBreakpoint.desktop;
  }

  ngOnInit() {
    this.currentWindowInnerWidth = this.windowResizeService.getCurrentInnerWidth();
    this.enableHidePanelAfterClickForSmallScreens();
    this.setAccessFormRouteListener();
  }

  ngOnDestroy() {
    this.windowResizeSubscription.unsubscribe();
    this.routerNavStartSubscription.unsubscribe();
    this.routerNavEndSubscription.unsubscribe();
  }

  private enableHidePanelAfterClickForSmallScreens(): void {
    this.windowResizeSubscription = this.windowResizeService.onResize$
      .subscribe(window => this.currentWindowInnerWidth = window.innerWidth);

    this.routerNavStartSubscription = this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart)
      )
      .subscribe(() => {
        const isNotDesktop = this.currentWindowInnerWidth < this.desktopScreenWidthBreakpoint;
        if (this.isLeftPanelCollapsed && isNotDesktop) {
          this.leftPanelChanged.emit(false);
        }
      });
  }

  private setAccessFormRouteListener(): void {
    this.routerNavEndSubscription = this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd)
      )
      .subscribe((event: RouterEvent) => {
        this.isOnForms = event.url.indexOf('form') >= 0;

        if (event.url.indexOf('e-commerce/company') >= 0) {
          this.document.querySelector('#content').classList.add('e-commerce-catalog');
        } else {
          this.document.querySelector('#content').classList.remove('e-commerce-catalog');
        }

      });
  }
}
