<nav class="navbar navbar-expand navbar-dark flex-row fixed-top rs-header">
  <ng-container *ngIf="isSearchCollapsed">
   <button class="navbar-toggler rs-leftpanel-toggler" type="button" aria-controls="navbarCollapse"
      aria-expanded="false" aria-label="Toggle navigation" (click)="toggleLeftPanel()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand rs-home-page-link" routerLink="/">
      <span class="rs-navbar-brand"></span>
    </a>
  </ng-container>
  <regsys-quick-search-widget class="flex-row ml-auto" [class.rs-search-expanded]="!isSearchCollapsed" (quickSearchChanged)="quickSearchChangedHandler($event)"></regsys-quick-search-widget>
  <ul class="navbar-nav flex-row ml-auto" [class.hidden]="!isSearchCollapsed">
      <li class="nav-item rs-shopping-cart-container d-md-flex">
          <regsys-shopping-cart></regsys-shopping-cart>
      </li>
      <li class="nav-item rs-notifications dropdown align-items-center d-flex" *isAuthorized="[]">
          <div #myDrop="ngbDropdown" ngbDropdown class="rs-dropdown-notifications rs-dropdown-arrow-top d-inline-block">
              <button class="rs-btn-icon-only rs-button btn border-0 px-4" [attr.aria-label]="'general.actions.openNotifications' | translate" id="dropdownMenuNotifications" ngbDropdownToggle>
                  <svg class="rs-btn-icon" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 22c1.1 0 2-.9 2-2h-4a2 2 0 002 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z" />
                  </svg>
              </button>
              <div class="p-0" ngbDropdownMenu aria-labelledby="dropdownMenuNotifications">
                  <regsys-latest-events class="d-block" [open]="myDrop.isOpen()"></regsys-latest-events>
              </div>
          </div>
      </li>
      <li class="nav-item rs-user-action dropdown" *isAuthorized="[]">
          <regsys-user-options></regsys-user-options>
      </li>
      <li class="nav-item rs-user-action" *isNotAuthorized="[]">
          <a class="nav-link" role="button" (click)="redirectToLogin()"  [title]="'headerComponent.login' | translate">
              {{ 'headerComponent.login' | translate }} <span class="rs-button-icon rs-login"></span>
          </a>
      </li>
      <li class="nav-item dropdown d-none d-sm-none d-md-flex">
          <regsys-localization></regsys-localization>
      </li>
  </ul>
</nav>