import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';

import { NotificationAggregator, Notification } from '@regsys/notifications';
import { AuthService } from '@regsys/security';
import { BrowserDetectionService } from '@regsys/common';
import { RegsysHttp } from '@regsys/http';

@Component({
  selector: 'regsys-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  isLeftPanelCollapsed = false;

  constructor(
    private auth: AuthService,
    private http: RegsysHttp,
    private browserDetection: BrowserDetectionService,
    private router: Router,
    private na: NotificationAggregator,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, this.browserDetection.getCurrentBrowser());
    this.auth.startupTokenRefresh();

    this.na.subscribe((not: Notification) => {
      if (not.forceLogout) {
        this.auth.logout();
        this.router.navigate(['/login']);
      } else if (not.flags?.isNotFound) {
        this.router.navigate(['404']);
      } else if (not.flags?.isUnhandledException) {
        this.router.navigate(['unknown-error', not.statusCode]);
      }
    });

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationEnd: {
          this.http.flush();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  leftPanelChangedHandler(isLeftPanelCollapsed: boolean): void {
    this.isLeftPanelCollapsed = isLeftPanelCollapsed;
  }
}
