import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './unknown-error.component.html'
})
export class UnknownErrorComponent implements OnInit {
    statusCode: number;

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        const paramStatusCode = this.route.snapshot.paramMap.get('statusCode');
        this.statusCode = paramStatusCode && parseInt(paramStatusCode, 10);
    }
}
