import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RegsysHttp } from '@regsys/http';


import { Address } from '../models/address.model'; 
import { Entity } from '../models/entity.model';


import { IRegsysWebConfig } from '@regsys/web/lib/shared/models/regsys_web.config';
import { RegsysWebConfig }  from '@regsys/web';

@Injectable()
export class EntityService {

  private config: IRegsysWebConfig;

  constructor(private regsysHttp: RegsysHttp, regsysConfig: RegsysWebConfig) {
    this.config = regsysConfig.config;
  }

  getEntityDetails(entityId: number): Observable<Entity> {
    const request = {
      url: `${this.config.apiUrl}${this.config.api.entity.details}`,
      id: entityId
    };
    return this.regsysHttp.doGet(request)
      .pipe(
        map(res => this.mapEntity(res.data))
      );
  }

  getEntityRelationships(entityId: number): Observable<any> {
    const request = {
      url: `${this.config.apiUrl}${this.config.api.entity.relationships}`,
      id: entityId
    };
    return this.regsysHttp.doGet(request);
  }

  getLobbyistEmployer(entityId: number): Observable<any> {
    const request = {
      url: `${this.config.apiUrl}rjsclobbyist/lobbyistEmployer`,
      id: entityId
    };
    return this.regsysHttp.doGet(request);
  }

  getLobbyistClient(entityId: number): Observable<any> {
    const request = {
      url: `${this.config.apiUrl}rjsclobbyist/lobbyistClient`,
      id: entityId
    };
    return this.regsysHttp.doGet(request);
  }


  getLobbyingActivityList(entityId: number): Observable<any> {
    const request = {
      url: `${this.config.apiUrl}rjsclobbyist/lobbyingActivity`,
      id: entityId
    };
    return this.regsysHttp.doGet(request);
  }


  getgovernmentFundingList(entityId: number): Observable<any> {
    const request = {
      url: `${this.config.apiUrl}rjsclobbyist/governmentFundings`,
      id: entityId
    };
    return this.regsysHttp.doGet(request);
  }

  getprivateFundingList(entityId: number): Observable<any> {
    const request = {
      url: `${this.config.apiUrl}rjsclobbyist/privateFundings`,
      id: entityId
    };
    return this.regsysHttp.doGet(request);
  }

  getOtherAddresses(id: number): Observable<Address[]> {
    const request = {
      url: `${this.config.apiUrl}${this.config.api.entity.otherAddresses}`,
      id
    };
    return this.regsysHttp.doGet(request)
      .pipe(
        map(res => this.otherAddressesMapper(res.data))
      );
  }

  getEntityNames(entityId: number): Observable<any> {
    const request = {
      url: `${this.config.apiUrl}${this.config.api.entity.names}`,
      id: entityId
    };
    return this.regsysHttp.doGet(request);
  }

  getEntityDocuments(entityId: number): Observable<any> {
    const request = {
      url: `${this.config.apiUrl}${this.config.api.entity.documents}`,
      id: entityId
    };
    return this.regsysHttp.doGet(request);
  }

  getLatestEvents(entityId: number, eventsCount: number): Observable<any> {

    const request = {
      url: `${this.config.apiUrl}${this.config.api.entity.latestEvents}`,
      id: entityId,
      parameters: { maxNumEvents: eventsCount }
    };

    return this.regsysHttp.doGet(request);
  }

  getMyEntities(): Observable<any> {
    const request = {
      url: `${this.config.apiUrl}${this.config.api.entity.myEntities}`
    };
    return this.regsysHttp.doGet(request);
  }

  private otherAddressesMapper(data: any[]): Address[] {
    if (!data) {
      return [];
    }

    const addresses: Address[] = [];

    data.forEach(add => {
      const addr = add.addressDetails;
      const a = new Address(
        addr.address1,
        addr.address2,
        addr.address3,
        addr.state,
        addr.stateId,
        addr.postcode,
        addr.city,
        addr.cityI,
        addr.country,
        addr.countryId,
        addr.attentionTo,
        addr.firmName,
        { id: add.entityOtherAddrTypeId, description: add.entityOtherAddrTypeDesc },
        addr.addressOneLine
      );
      a.addressId = addr.addressId;
      addresses.push(a);
    });
    return addresses;
  }

  private mapEntity(data: any): Entity {
    if (!data) {
      return null;
    }

    const e = new Entity(
      data.entityId,
      data.entityRegNumber,
      data.entityRegName,
      data.entityStatusDesc,
      data.entityTypeDesc,
      data.entityRegDate ? new Date(data.entityRegDate) : null,
      data.entityTypeId,
      data.entityExpiryDate ? new Date(data.entityExpiryDate) : null,
      data.annualReturnDueDate ? new Date(data.annualReturnDueDate) : null,
      data.personInd,
      data.entityStatusEffectiveDate
    );

    if (data.entityRegAddress) {
      const regAddressObj = data.entityRegAddress as Address;
      const regAddress = new Address(
        regAddressObj.address1,
        regAddressObj.address2,
        regAddressObj.address3,
        regAddressObj.state,
        regAddressObj.stateId,
        regAddressObj.postcode,
        regAddressObj.city,
        regAddressObj.cityId,
        regAddressObj.country,
        regAddressObj.countryId,
        regAddressObj.attentionTo,
        regAddressObj.firmName,
        { id: 'regAddress', description: 'Reg. Address' },
        regAddressObj.addressOneLine
      );
      regAddress.addressId = regAddressObj.addressId;
      e.addAddress(regAddress);
    }

    if (data.entityMailAddress) {
      const mailAddressObj = data.entityMailAddress as Address;
      const mailAddress = new Address(
        mailAddressObj.address1,
        mailAddressObj.address2,
        mailAddressObj.address3,
        mailAddressObj.state,
        mailAddressObj.stateId,
        mailAddressObj.postcode,
        mailAddressObj.city,
        mailAddressObj.cityId,
        mailAddressObj.country,
        mailAddressObj.countryId,
        mailAddressObj.attentionTo,
        mailAddressObj.firmName,
        { id: 'mailAddress', description: 'Mailing Address' },
        mailAddressObj.addressOneLine
      );
      e.addAddress(mailAddress);
    }

    return e;
  }
}
