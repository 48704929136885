import { Component, Input } from '@angular/core';
import { relationship } from '../../../../models/relationship.model';


@Component({
    selector: 'regsys-entity-client-details',
    templateUrl: './entity-client-details.component.html'
})
export class EntityClientDetailsComponent {
    @Input() entity: relationship;
}
