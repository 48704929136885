export const ClientConfig = {
  ui: {
    defaultLanguage: 'EN-CA',
    defaultCountryId: 38,
    date: {
			format: 'DD-MMM-YYYY',
			timeFormat: 'MM/DD/YYYY HH:mm'
		},
    currencyOptions: {
			currency: 'CAD',
			currencyHTML: '$',
			currencyDecimalLimit: 2,
			currencySeparatorSymbol: ',',
			currencyIntegerLimit: 12
		}
  },
  formOptions: {
    allowContinueWithErrors: true,
		shoppingCartEnabled: false,
    renewalWindowFromDays : 10000,
    renewalWindowToDays : 60
  },
  searchOptions: {
    minimumCharacters: 3,
      quickSearchFilter: [{
          key: 'entityTypeId',
          order: 1,
          values: [300,301,302],
          include: true
      }]
  },
  screenWidthBreakpoint: {
    mobile: 576,
    tablet: 768,
    desktop: 1200
    },
    formsHistoryCancelButtonVisible: true,
    governmentCustomerTypeId: [5],
    hideSSOLogin: false,
    appInsightsSettings: {
        instrumentationKey: ''
    }
};
