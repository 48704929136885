<div class="page container">
    <div class="page-content">
        <section class="action-status error text-center" role="alert">
            <span class="display-2 text-muted mb-5">{{ statusCode }}</span>
            <h2 class="rs-h2 mb-3">{{ 'unknownErrorComponent.unexpectedErrorOccurred' | translate }}</h2>
            <p class="text-muted font-weight-normal mb-7" [innerHTML]="'unknownErrorComponent.pleaseTryAgain' | translate"></p>
            <button class="rs-button btn btn-outline-primary" type="button" routerLink="/dashboard">
                <span class="button-name">{{ 'notFoundErrorComponent.dashboard' | translate }}</span>
            </button>
        </section>
    </div>
</div>