

<div *ngFor="let row of lobbyingActivities" style="padding-top: 20px;">
    <table style=" border: 1px solid #EAEAEF; border-spacing: 0;border-collapse: separate;border-radius: 10px;width: 100%;">
        <tr *ngIf="!hideActivityPeriod">
            <td>
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{
                    'forms.lobbyist.labels.activityPeriod' | translate
                    }}
                </h3>
            </td>
            <td>{{ row.activityPeriod }}</td>
        </tr>
        <tr>
            <td>
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{
                        'forms.lobbyist.labels.lobbyingDescription' | translate
                    }}
                </h3>
            </td>
            <td>{{ row.undertakingName  }}</td>
        </tr>
        <tr>
            <td>
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{
                        'forms.lobbyist.labels.subjectMatter' | translate
                    }}
                </h3>
            </td>
            <td>{{ row.subjectMatters  }}</td>
        </tr>
        <tr>
            <td>
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{
                        'forms.lobbyist.labels.lobbyTargets' | translate
                    }}
                </h3>
            </td>
            <td> {{ row.targets }}</td>
        </tr>
        <tr>
            <td>
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{
                        'forms.lobbyist.labels.communicationTechniques' | translate
                    }}
                </h3>
            </td>
            <td>{{ row.communicationTech }}</td>
        </tr>
        <tr>
            <td>
                <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">
                    {{
                        'forms.lobbyist.labels.lobbyingMLA' | translate
                    }}
                </h3>
            </td>
            <td> {{ row.mlAswith }}</td>
        </tr>
    </table>
</div>

<mat-paginator #paginator [class.d-none]="gridlobbyingActivities?.length < 5" class="rs-regular-text mt-2 mt-lg-3"
               [length]="gridlobbyingActivities?.length" [pageIndex]="0" [pageSize]="5" (page)="handlePaging($event)">
</mat-paginator>

<ng-template #emptyGrid>
    <div class="empty">
        <div class="title">{{ 'forms.lobbyist.labels.noLobbyingActivities' | translate }}</div>
    </div>
</ng-template>