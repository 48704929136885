export class AddressType {
  id: string;
  description?: string;
}

export class Address {
  addressId: number;

  constructor(
    public address1?: string,
    public address2?: string,
    public address3?: string,
    public state?: string,
    public stateId?: number,
    public postcode?: string,
    public city?: string,
    public cityId?: number,
    public country?: string,
    public countryId?: number,
    public attentionTo?: string,
    public firmName?: string,
    public type?: AddressType,
    public addressOneLine?: string
  ) {}

  get localizationKey(): string {
    return `entity.addresses.type.${this.type.id}`;
  }

  getAddressSingleLine() {
    return [
      this.address1,
      this.address2,
      this.address3,
      this.city,
      this.state,
      this.country,
      this.postcode
    ]
      .filter(val => val)
      .join(', ');
  }
}
