import { Component, OnInit } from '@angular/core';
import { GetRequest, PostRequest, RegsysHttp, LocalStorageService } from '@regsys/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { RjscSSOLogOff } from '../../models/RjscSSOLogOff';

@Component({
  template:`
  `
})
export class SSOLogoutComponent implements OnInit {

  
  constructor(private router: Router , 
    private httpService: RegsysHttp, 
    private localStorageService: LocalStorageService) { }
  ngOnInit() {
    const param: RjscSSOLogOff = new RjscSSOLogOff() ;
    param.sSORPID = this.localStorageService.GetStringValue('rpid');
    param.sSOSessionID = this.localStorageService.GetStringValue('sessionID');
    param.encryptedUserId = this.localStorageService.GetStringValue('nid');
    const postRequest: PostRequest = {
        url: `${environment.apiUrl}LobbyistSSO/logoff`,
        parameters: param
      };
      this.httpService.doPost(postRequest).subscribe(res => {
        this.localStorageService.RemoveValue('redirectUrl');
        this.router.navigate(['home']);
      });
  }

}
