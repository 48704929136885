import { environment } from './../../../environments/environment.qa-dev';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import forEach from 'lodash-es/forEach';

import { SearchResponse } from '../../models/search-response.model';
import { RegSysDataSource, rsListAnimation } from '@regsys/web';
import { SearchResponseItem } from '../../models/search-response-item.model';
import { DatePipe } from '@angular/common';


@Component({
    selector: 'regsys-search-results-grid',
    templateUrl: './search-results-grid.component.html',
    animations: [rsListAnimation]
})

export class SearchResultsGridComponent implements AfterViewInit {

    @Input() searchResult: SearchResponse;
    @Input() showRowSelectableFlag: boolean;
    @Input() isPrinting: boolean;

    gridPageLimit = 10;
    gridFirst = 1;
    gridLast = this.gridPageLimit;

    displayedColumns = ['EntityRegName','RegAddressOneLine', 'ParentEntityRegNumber','ParentEntityRegName','ParentCivicAddressOneLine', 'RelationshipTypeDesc','RelationshipStartDate' , 'RelationshipEndDate','ParentEntityStatusDesc', 'entityId'];
    dataSource: RegSysDataSource = new RegSysDataSource([], null, null);

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private router: Router) { }

    ngAfterViewInit(): void {
        this.paginator.page.subscribe(() => this.setDataSource());

        if (this.paginator && this.searchResult && this.searchResult.results && this.searchResult.results.length > 0) {
            this.setDataSource();
        }
    }

    downloadAsCsv() {
        const datePipe = new DatePipe(environment.ui.defaultLanguage);
        const CsvArray = [];
        forEach(this.searchResult.results, (item: SearchResponseItem) => {
            CsvArray.push({
                EntityRegName: item.entityRegName,
                RegAddressOneLine: item.regAddressOneLine,
                ParentEntityRegNum: item.parentEntityRegNumber,
                ParentEntityRegName: item.parentEntityRegName,
                ParentEntityRegAddress: item.parentCivicAddressOneLine,
                RelationshipTypeDesc: item.relationshipTypeDesc,
                ParentEntityStatusDesc: item.parentEntityStatusDesc,
                RelationshipStartDate: datePipe.transform(item.relationshipStartDate, 'dd/MM/yyyy'),
                RelationshipEndDate: item.relationshipEndDate != null ? datePipe.transform(item.relationshipEndDate , 'dd/MM/yyyy') : ''

            });
        });

        const options = {
            showLabels: true
        };
        const csvReport = new Angular2Csv(CsvArray, 'Person Search', { headers: Object.keys(CsvArray[0]) });
    }

    onRedirect(entityId: any) {
        this.router.navigate(['e-commerce', 'company', entityId]);
    }

    getCurrentPageTotals(page) {
        const maxTotalOfPage = page.pageSize * (page.offset + 1);
        this.gridLast = maxTotalOfPage > this.searchResult.results.length ? this.searchResult.results.length : maxTotalOfPage;
        this.gridFirst = maxTotalOfPage - page.pageSize + 1;
    }

    private setDataSource() {
        this.dataSource = new RegSysDataSource(this.searchResult.results, this.paginator, null);
    }
}
