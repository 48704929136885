import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'regsys-footer',
  templateUrl: './app-footer.component.html'
})
export class FooterComponent {
  version = environment.version;

  constructor() {}
}
