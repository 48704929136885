<div class="container-fluid p-2 p-lg-5">
    <div class="row">
        <div class="col-md-6">
            <h2 class="rs-h2">
                {{ 'dashboardComponent.hi' | translate:{ name: loggedUser.firstName || loggedUser.customerUserName } | titlecase }}
                <span>{{ 'dashboardComponent.friendlyQuestion' | translate }}</span>
            </h2>

            <regsys-custom-actions [customActions]="actionsList">
                <mat-card class="highlight mb-4" tabindex="0">
                    <mat-card-title class="mb-1">
                        {{ 'forms.lobbyist.dashboardComponent.customActions.highlight.title' | translate }}
                    </mat-card-title>
                    <mat-card-content [innerHTML]="'forms.lobbyist.dashboardComponent.customActions.highlight.text' | translate">
                    </mat-card-content>
                    <button class="rs-button btn btn-outline-primary"
                            (click)="startNewFilingPanelService.open(); $event.stopImmediatePropagation();">
                        <span class="button-name">
                            {{ 'forms.lobbyist.dashboardComponent.customActions.highlight.action' | translate }}
                        </span>
                    </button>
                </mat-card>
            </regsys-custom-actions>
            <regsys-custom-actions [customActions]="actionsList">
                <mat-card class="highlight mb-4" tabindex="0">
                    <mat-card-title class="mb-1">
                        {{ 'forms.lobbyist.dashboardComponent.search.highlight.title' | translate }}
                    </mat-card-title>
                    <mat-card-content [innerHTML]="'forms.lobbyist.dashboardComponent.search.highlight.text' | translate">
                    </mat-card-content>
                    <button class="rs-button btn btn-outline-primary"
                            (click)="goToSearch()">
                        <span class="button-name">
                            {{ 'forms.lobbyist.dashboardComponent.search.highlight.action' | translate }}
                        </span>
                    </button>
                </mat-card>
            </regsys-custom-actions>
        </div>
        <ng-container *isAuthorized="[]">
            <regsys-entities *ngIf="displayMyEntities" class="col-md-6"></regsys-entities>
        </ng-container>
        <!--<div class="col-lg-4 mb-4">
            <h2 class="rs-h2 mb-3 text-center">{{ 'dashboardComponent.overview' | translate }}</h2>
            <regsys-graphs [colorSchemeConfig]="colorScheme"
        (displayAllFilingsTriggered)="routingHandler($event, 'filings/tab/')">
            </regsys-graphs>
        </div>-->
    </div>
</div>