import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@regsys/http';

// @Regsys Package
import { AuthService, CustomerPrivileges, CustomerUser, Privilege, SecurityEvent } from '@regsys/security';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'regsys-user-options',
    templateUrl: './app-user-options.component.html'
})
export class UserOptionsComponent implements OnInit {

    constructor(private authService: AuthService, private securityEvent: SecurityEvent,
        private router: Router, private eref: ElementRef,
        private localStorageService: LocalStorageService) { }

    showUserSection: boolean;
    custUser: CustomerUser = new CustomerUser(null, '');
    showAdmin: boolean;

    isLoggedIn: boolean;

    @HostListener('document:click')
    onMouseEnter(event: Event) {
        if (event && !this.eref.nativeElement.contains(event.target)) {
            this.showAdmin = false;
        }
    }

    ngOnInit() {
        this.initialize(this.authService.isLoggedIn());

        this.securityEvent.loggedIn.subscribe((loggedIn: boolean) => {
            this.initialize(loggedIn);
        });
    }

    logout() {

        this.authService.logout();
        this.securityEvent.loggedIn.publish(false);

        this.router.navigate(['/ssologout']);
    }
    private initialize(loggedIn: boolean) {
        this.showUserSection = loggedIn;
        if (this.showUserSection) {
            this.custUser = this.authService.getAuthCustomerUser();
            if (!this.custUser) {
                this.custUser = new CustomerUser(null, '');
            }
            const custPrivileges = this.localStorageService.GetValue<CustomerPrivileges>('cust_privileges');
            this.isLoggedIn = this.authService.isLoggedIn();
            const loggedCustomer: CustomerUser = this.authService.getAuthCustomerUser();
            if (loggedCustomer != null) {
                if (environment.governmentCustomerTypeId.indexOf(loggedCustomer.customerTypeId) !== -1) {
                    const customPriv = new Privilege('IsGovernmentUser', true);
                    custPrivileges.privileges.push(customPriv);
                    this.localStorageService.SetValue('cust_privileges', custPrivileges);
                } else {
                    const customPriv = new Privilege('IsGovernmentUser', false);
                    custPrivileges.privileges.push(customPriv);
                    this.localStorageService.SetValue('cust_privileges', custPrivileges);
                }
            } else {

            }

        }
    }
}
