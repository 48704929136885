<nav class="rs-leftpanel" id="leftpanel" [class.active]="isLeftPanelCollapsed">
  <section class="rs-buttons">

    <a *isAuthorized="[]" class="item" routerLink="/dashboard" routerLinkActive="active" protract="dashboardNav" 
    [title]="'navigationComponent.dashboard' | translate">
      <span class="icon rs-dashboard"></span>
      <strong>{{ 'navigationComponent.dashboard' | translate }}</strong>
    </a>

    <a class="item" routerLink="/search" routerLinkActive="active" protract="searchNav" 
    [title]="'navigationComponent.search' | translate">
      <span class="icon rs-search"></span>
      <strong>{{ 'navigationComponent.search' | translate }}</strong>
    </a>
    <a *isAuthorized="['IsGovernmentUser']" class="item" routerLink="/person-search" routerLinkActive="active" protract="searchNav" 
    [title]="'navigationComponent.person-search' | translate">
      <span class="icon rs-person-search"></span>
      <strong>{{ 'navigationComponent.person-search' | translate }}</strong>
  </a>

    <a *isAuthorized="['CanCreateSubmission']" class="item" [class.active]="isOnForms" (click)="startNewFilingPanelService.open(); $event.stopImmediatePropagation();" [title]="'navigationComponent.newFiling' | translate">
      <span class="icon rs-newFiling"></span>
      <strong>{{ 'navigationComponent.newFiling' | translate }}</strong>
    </a>

    <a *isAuthorized="[]" class="item" routerLink="/filings" routerLinkActive="active" protract="myFilingsNav" [title]="'navigationComponent.myFilings' | translate">
      <span class="icon rs-myFilings"></span>
      <strong>{{ 'navigationComponent.myFilings' | translate }}</strong>
    </a>

    <a *isAuthorized="[]" class="item" routerLink="/messages" routerLinkActive="active" protract="myMessagesNav" [title]="'navigationComponent.messages' | translate">
      <span class="icon rs-myMessages"></span>
      <strong>{{ 'navigationComponent.messages' | translate }}</strong>
    </a>

    <a class="item" *ngIf="false"  routerLink="/case" routerLinkActive="active" protract="casesNav" [title]="'navigationComponent.cases' | translate">
      <span class="icon rs-cases"></span>
      <strong>{{ 'navigationComponent.cases' | translate }}</strong>
    </a>

    <a *isAuthorized="[]" class="item" routerLink="/orders" routerLinkActive="active" protract="ordersNav" [title]="'navigationComponent.orders' | translate">
      <span class="icon rs-orders"></span>
      <strong>{{ 'navigationComponent.orders' | translate }}</strong>
    </a>

    <a *isAuthorized="['CanViewTrans']" class="item" routerLink="/transactions" routerLinkActive="active" protract="paymentsNav" [title]="'navigationComponent.payments' | translate">
      <span class="icon rs-payments"></span>
      <strong>{{ 'navigationComponent.payments' | translate }}</strong>
    </a>

    <a *isAuthorized="['Administrator']" class="item" routerLink="/account" routerLinkActive="active" protract="accountNav" [title]="'navigationComponent.account' | translate">
      <span class="icon rs-account"></span>
      <strong>{{ 'navigationComponent.account' | translate }}</strong>
    </a>

    <a class="item" [href]="'navigationComponent.helpLink' | translate" target="_blank" routerLinkActive="false" protract="helpNav" [title]="'navigationComponent.help' | translate">
      <span class="icon rs-help"></span>
      <strong>{{ 'navigationComponent.help' | translate }}</strong>
    </a>

  </section>
  <p class="rs-app-version">{{ 'navigationComponent.version' | translate: { version: version} }}</p>
</nav>