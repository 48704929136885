import { AppNavComponent } from './app-nav/app-nav.component';
import { HeaderComponent } from './app-header/app-header.component';
import { UserOptionsComponent } from './app-user-options/app-user-options.component';
import { AppNotificationComponent } from './app-notifications/app-notifications.component';
import { LocalizationComponent } from './app-localization/app-localization.component';
import { PublicSearchComponent } from './home/public-search/public-search.component';
import { FooterComponent } from './app-footer/app-footer.component';

export const components = [
    AppNavComponent,
    HeaderComponent,
    UserOptionsComponent,
    AppNotificationComponent,
    LocalizationComponent,
    PublicSearchComponent,
    FooterComponent
];
