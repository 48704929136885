
import { NgModule, Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { environment } from '../environments/environment';
import { RegSysFormsModule, DynamicFormConfig, DynamicFormConfigService, CallbackInjectorService, CallbackProcessor, RegSysFormControl, QuestionBase } from '@regsys/forms';
import { RegSysCommonModule, CommonConfig, CommonConfigService, FindControl } from '@regsys/common';
import { RegSysHttpModule, RegsysHttp, PostRequest, GetRequest } from '@regsys/http';
import { RegSysSecurityModule, RegsysAuthConfig } from '@regsys/security';
import { RegSysNotificationModule } from '@regsys/notifications';
import { RegSysPaymentModule, PaymentConfig } from '@regsys/payment';
import {
    RegsysWebConfig,
    CustomActionsConfig,
    RegSysRegistrationModule,
    RegSysAdministrationModule,
    RegSysMessagesModule,
    RegSysAdvancedSearchModule,
    RegSysFormsHistoryModule,
    RegSysTransactionsModule,
    RegSysECommerceModule,
    RegSysFormsFilingModule,
    RegSysLatestEventsModule,
    RegSysQuickSearchWidgetModule,
    RegSysEntitiesModule,
    RegSysCaseModule,
    RegSysGraphsModule,
    ShoppingCartModule,
    RegSysStatisticalOverviewWidgetModule,
    RegSysCustomActionsModule,
    RegSysWatchlistModule,
    SharedModule
} from '@regsys/web';

import { CustomCallbackService } from '../app.extension/forms/services/custom-callback.service';


@Injectable()
export class CustomCallbackProcessor extends CallbackProcessor {

    // Customized system Callbacks implementations
    protected innerFormExecuted(questionTriggering: any, form: FormGroup, apiResult: any) {
        if (questionTriggering.key === '00000002-94f4-4ff4-9f80-888904249559') {
            console.log('Customized');
        }
        super.innerFormExecuted(questionTriggering, form, apiResult);
    }

}


@NgModule({
    imports: [
        RegSysNotificationModule.forRoot(),
        RegSysHttpModule.forRoot(),
        RegSysCommonModule.forRoot(),
        RegSysSecurityModule,
        RegSysFormsModule,
        RegSysPaymentModule,
        RegSysRegistrationModule,
        RegSysAdministrationModule,
        RegSysMessagesModule,
        RegSysAdvancedSearchModule,
        RegSysFormsHistoryModule,
        RegSysTransactionsModule,
        RegSysECommerceModule,
        SharedModule,
        RegSysFormsFilingModule,
        RegSysLatestEventsModule,
        RegSysQuickSearchWidgetModule,
        RegSysEntitiesModule,
        RegSysCaseModule,
        RegSysGraphsModule,
        RegSysStatisticalOverviewWidgetModule,
        RegSysCustomActionsModule,
        RegSysWatchlistModule
    ],
    providers: [
        { provide: RegsysAuthConfig, useFactory: authConfigFactory },
        { provide: DynamicFormConfigService, useFactory: dynamicFormConfigfactory },
        { provide: CallbackInjectorService, useClass: CustomCallbackService },
        { provide: RegsysWebConfig, useFactory: regsysWebConfigFactory },
        { provide: PaymentConfig, useFactory: paymentConfigFactory },
        { provide: CommonConfigService, useFactory: commonConfigFactory },
        { provide: CallbackInjectorService, useClass: CustomCallbackService },
        { provide: CustomActionsConfig, useFactory: CustomActionsConfigFactory },
        RegsysHttp
    ],
    exports: [
        RegSysSecurityModule,
        RegSysFormsModule,
        RegSysCommonModule,
        RegSysHttpModule,
        RegSysNotificationModule,
        RegSysPaymentModule,
        RegSysRegistrationModule,
        RegSysAdministrationModule,
        RegSysMessagesModule,
        RegSysAdvancedSearchModule,
        RegSysFormsHistoryModule,
        RegSysTransactionsModule,
        RegSysECommerceModule,
        SharedModule,
        RegSysFormsFilingModule,
        RegSysLatestEventsModule,
        RegSysQuickSearchWidgetModule,
        RegSysEntitiesModule,
        RegSysCaseModule,
        RegSysGraphsModule,
        RegSysStatisticalOverviewWidgetModule,
        ShoppingCartModule,
        RegSysCustomActionsModule
    ]
})
export class RegsysLoader { }

export function paymentConfigFactory() {
    return new PaymentConfig({
        apiUrl: environment.apiUrl,
        activeProvider: environment.paymentOptions.paymentProvider,
        stripe_pk: environment.paymentOptions.stripe.stripe_pk,
        realexTrustedUrl: environment.paymentOptions.realex.trustedUrl,
        realexFormSubmitUrl: environment.paymentOptions.realex.submitUrl,
        types: environment.paymentOptions.types,
        gpsFormSubmitUrl: environment.paymentOptions.gps.submitUrl,
        currency: environment.ui.currencyOptions.currency,
        defaultCountryId: environment.ui.defaultCountryId,
        api: {
            citypay: {
                config: 'citypayaux/config',
                create: 'citypayaux/create-payment'
            },
            realex: {
                config: 'realexaux/config'
            },
            gps: {
                config: 'gpspaymentaux/configLobbyist'
            },
            magicpay: {
                config: 'magicpayaux/config',
                pending: 'magicpayaux/pre-submit',
                receive: 'magicpayaux/receive-payment'
            }
        }
    });
}

export function authConfigFactory() {
    return new RegsysAuthConfig({
        apiUrl: environment.apiUrl
    });
}

export function commonConfigFactory() {
    return new CommonConfig({
        apiUrl: environment.apiUrl,
        allowedInputFileType: ['application/pdf'],
        allowedFileType: ['pdf'],
        maxDocSize: 5,
        localisationFilePath: '/assets/localization/',
        defaultLanguage: environment.ui.defaultLanguage,
        dateFormat: environment.ui.date.format,
        dateTimeFormat: environment.ui.date.timeFormat,
        screenWidthBreakpoint: environment.screenWidthBreakpoint,
        defaultTimezoneData: 'America/Halifax|LMT AST ADT AWT APT|4e.o 40 30 30 30|0121212121212121212121212121212121212121212121212134121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2IsHJ.A xzzJ.A 1db0 3I30 1in0 3HX0 IL0 1E10 ML0 1yN0 Pb0 1Bd0 Mn0 1Bd0 Rz0 1w10 Xb0 1w10 LX0 1w10 Xb0 1w10 Lz0 1C10 Jz0 1E10 OL0 1yN0 Un0 1qp0 Xb0 1qp0 11X0 1w10 Lz0 1HB0 LX0 1C10 FX0 1w10 Xb0 1qp0 Xb0 1BB0 LX0 1td0 Xb0 1qp0 Xb0 Rf0 8x50 iu0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 3Qp0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 3Qp0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 6i10 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|39e4',
        api: {
            downloadTransactionsCsv:'rjsctransaction/downloadCustomerPaymentsLobbyist'
        }
    });
}

export function dynamicFormConfigfactory() {
    return new DynamicFormConfig({
        apiUrl: environment.apiUrl,
        dateFormat: environment.ui.date.format,
        dayMonthFormat: 'DD/MM',
        publicIndIcon: 'fa fa-eye',
        dayMonthMask: [/\d/, /\d/, '/', /\d/, /\d/],
        dateMask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
        captchasitekey: '6LfErLgUAAAAAHRR66EVpxgPA78eRC0HLrN-47ZG',
        currencyOptions: {
            currencyHTML: '$'
        }
    });
}

export function regsysWebConfigFactory() {
    return new RegsysWebConfig({
        apiUrl: environment.apiUrl,
        // @Sample values
        currencyOptions: environment.ui.currencyOptions,
        defaultCountryId: environment.ui.defaultCountryId,
        catalogQuickSearchFilter: environment.searchOptions.quickSearchFilter,
        formsHistoryCancelButtonVisible: environment.formsHistoryCancelButtonVisible,
        dashboardQuickSearchFilter: environment.searchOptions.quickSearchFilter,
        watchlistQuickSearchFilter: environment.searchOptions.quickSearchFilter,
        formOptions: {
            allowContinueWithErrors: environment.formOptions.allowContinueWithErrors,
            addRelatedEntityIdOnStarterForm: true,
            showPreviewButton: false
        },
        formattingOptions: {
            dateFormat: environment.ui.date.format,
            dateTimeFormat: environment.ui.date.timeFormat
        },
        isShoppingCartEnabled: environment.formOptions.shoppingCartEnabled,
        // @Sample values
        accountTopUp: {
            minimumTopUpAmount: 10
        },
        tabsVisibility: {
            // formsHistoryStatusVisibleTabs: [2, 3, 6]
        },
        dashboard: {
            latestEventsCount: 5
        },
        enquiries: {
            disableMissingDocumentPurchase: false
        },
        transaction: {
            showDownloadCsv: true
        },
        profileOptions: {
            showPasswordInputs: false
        },
        api: {
            analytics: {
                submissionsOverview: 'rjsclobbyist/submissions-overview'
            },
            submissions: {
                draftSubmit: 'rjscsubmission/draft-submit',
                draft: {
                    get: 'rjscSubmission/draft'
                },
                return: {
                    get: 'rjscSubmission/return'
                },
                bystatus: 'rjsclobbyist/bystatus',
                initialLoad: 'rjscSubmission/load-initial',
                catalog:'rjsclobbyist/catalog'
            },
            entity: {
                quickSearch: 'rjsclobbyist/public-quick-search',
                search: 'rjsclobbyist/search',
                relationships: 'rjscentity/allrelationships',
                latestEvents: 'rjscentity/latest-events',
                details: 'rjscentity',
                myEntities: 'rjsclobbyist/myentities'                
            },
            enquiry: {
                documents: 'rjscenquiry/products/lobbdocuments',
                certificates: 'rjscenquiry/products/certificates',
                bystatus:'rjscenquiry/by-status-Lobbyist',
                reports:'rjscenquiry/lobbproducts/reports'
            },
            message: {
                get: 'rjscmessage',
                getAll:'rjscmessage/alllobbyist'
            },
            shoppingCart: {
                prepareForPayment: 'rjscshoppingCart/prepare'
            },
            transaction: {
                getall : 'rjsclobbyist'
            },
            configuration : {
                enquiryTypes: 'rjsc/enquiry/types-lobbyist'
            },
            watchlist: {
                get:'rjscwatchlistlobbyist'
            }


        }
    });
}

export function CustomActionsConfigFactory(): CustomActionsConfig {

    const customActionsConfig = new CustomActionsConfig();
    customActionsConfig[1] = [
        {
            titleLocalizationKey: 'customActionsSample.renewRegistration.title',
            textLocalizationKey: 'customActionsSample.renewRegistration.description',
            isRenewal: true,
            action: {
                nameLocalizationKey: 'customActionsSample.renewRegistration.buttonText',
                link: 1,
                color: 'primary',
            }
        }
    ];
    customActionsConfig[2] = [
        {
            titleLocalizationKey: 'customActionsSample.renewRegistration.title',
            textLocalizationKey: 'customActionsSample.renewRegistration.description',
            isRenewal: true,
            renewalAllowedDays: 10,
            action: {
                nameLocalizationKey: 'customActionsSample.renewRegistration.buttonText',
                link: 2,
                color: 'primary',
            }
        },
        {
            titleLocalizationKey: 'customActionsSample.1.title',
            textLocalizationKey: 'customActionsSample.1.description',
            isRenewal: false,
            action: {
                nameLocalizationKey: 'customActionsSample.1.buttonText',
                link: 1,
                color: 'primary'
            }
        }
    ];


      customActionsConfig[300] = [
        {
            titleLocalizationKey: 'customActionsSample.renewRegistration.title',
            textLocalizationKey: 'customActionsSample.renewRegistration.description',
            isRenewal: true,
            //renewalAllowedDays: 30, 
            renewalRangeDays: {
                from: environment.formOptions.renewalWindowFromDays,
                to: environment.formOptions.renewalWindowToDays
            },


            action: {
                nameLocalizationKey: 'customActionsSample.renewRegistration.buttonText',
                link: 601,
                color: 'primary',
            }
        }
    ];

    customActionsConfig[301] = [
        {
            titleLocalizationKey: 'customActionsSample.renewRegistration.title',
            textLocalizationKey: 'customActionsSample.renewRegistration.description',
            isRenewal: true,
            //renewalAllowedDays: 30, 
            renewalRangeDays: {
                from: environment.formOptions.renewalWindowFromDays,
                to: environment.formOptions.renewalWindowToDays
            },


            action: {
                nameLocalizationKey: 'customActionsSample.renewRegistration.buttonText',
                link: 601,
                color: 'primary',
            }
        }
    ];
    customActionsConfig[302] = [
        {
            titleLocalizationKey: 'customActionsSample.renewRegistration.title',
            textLocalizationKey: 'customActionsSample.renewRegistration.description',
            isRenewal: true,
            //renewalAllowedDays: 30, 
            renewalRangeDays: {
                from: environment.formOptions.renewalWindowFromDays,
                to: environment.formOptions.renewalWindowToDays
            },


            action: {
                nameLocalizationKey: 'customActionsSample.renewRegistration.buttonText',
                link: 601,
                color: 'primary',
            }
        }
    ];

    return customActionsConfig;
}