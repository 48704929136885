import { environment } from '../../../environments/environment.qa-dev';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { tap, map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';

import { ErrorNotification, NotificationAggregator } from '@regsys/notifications';
import { rsFadeAnimation } from '@regsys/web';
import { SessionStorageService } from '@regsys/http';

// Models
import { SearchRequest } from '../../models/search-request.model';
import { SearchResponse } from '../../models/search-response.model';
import { StoredResult } from '../../models/stored-result.model';
import { OptionItem } from '../../models/option-item.model';
import { AuthService, CustomerUser } from '@regsys/security';
import { Router } from '@angular/router';

// Services
import { PersonSearchService } from '../../services/person-search.service';

@Component({
    templateUrl: 'person-search.component.html',
    animations: [rsFadeAnimation]
})

export class PersonSearchComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    states: OptionItem[];
    isSearching: boolean;
    warningInd: boolean;
    searchResultsExpiredInd: boolean;

    searchResult: SearchResponse;

    constructor(
        private searchService: PersonSearchService,
        private sessionStorageService: SessionStorageService,
        private na: NotificationAggregator,
        private authService: AuthService,
        private router: Router
    ) { }

    ngOnInit(): void {
        const loggedCustomer: CustomerUser = this.authService.getAuthCustomerUser();
        // To Change the CustomerId  to be CustomerTypeId after changing the core code
        if (environment.governmentCustomerTypeId.indexOf(loggedCustomer.customerTypeId) == -1) {
            this.router.navigate(['/dashboard']);
        } else {
            this.getStoredResults();
            this.states = this.searchService.getStatesDdl();
        }
    }

    doSearch(searchRequest: SearchRequest) {
        this.isSearching = true;
        this.searchResult = undefined;
        this.searchService.search(searchRequest).pipe(
            tap(() => this.isSearching = false),
            map((res) => {
                if (res.success && res.data) {
                    this.searchResult = new SearchResponse(res.data, moment().toDate());
                } else {
                    this.handleError(new ErrorNotification(res.message));
                }
            }),
            tap(() => {
                if (this.searchResult.results.length == 0) {
                    this.resetStoredValues();
                    return;
                }
                this.storeSearchResults();
            })
        ).subscribe();
    }

    handleNotification(message: string) {
        this.handleError(new ErrorNotification(message));
    }

    reset() {
        this.resetStoredValues();
        this.searchResult = undefined;
    }

    private getStoredResults() {
        const storedResult: StoredResult = this.sessionStorageService.GetValue<StoredResult>('stored_result_persons');
        if (storedResult == null) {
            return;
        }

        storedResult.saveDate = moment(storedResult.saveDate).toDate();
        storedResult.expiryDate = moment(storedResult.expiryDate).toDate();

        this.searchResultsExpiredInd = storedResult && new Date() > storedResult.expiryDate;

        if (this.searchResultsExpiredInd) {
            this.resetStoredValues();
            return;
        }

        this.searchResult = new SearchResponse(storedResult.results, storedResult.saveDate);
    }

    private storeSearchResults() {
        const expiryDate = moment().add(10, 'minutes').toDate();
        const storedResult = new StoredResult(this.searchResult.searchDate, expiryDate, this.searchResult.results);
        this.sessionStorageService.SetValue('stored_result_persons', storedResult);
    }

    private resetStoredValues() {
        this.sessionStorageService.RemoveValue('stored_result_persons');
    }

    private handleError(error: ErrorNotification) {
        this.na.publish(error);
    }
}
