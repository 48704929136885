import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { SearchRequest } from '../../models/search-request.model';
import isEmpty from 'lodash-es/isEmpty';

@Component({
    selector: 'regsys-search-by-person',
    templateUrl: './search-by-person.component.html'
})

export class SearchByPersonComponent implements OnInit {

    @Input() isSearching: boolean;
    @Output() searchRequest = new EventEmitter<SearchRequest>();
    @Output() notificationMessage = new EventEmitter<string>();
    @Output() clearResults = new EventEmitter<void>();

    searchByPersonForm: FormGroup;

    constructor(private formBuilder: FormBuilder) { }

    ngOnInit() {
        this.searchByPersonForm = this.formBuilder.group({
            nameSearchType: [3],
            FirstName: [''],
            LastName: [''],
            MiddleName: [''],
            personInd: [true]
        });
    }

    reset() {
        this.searchByPersonForm.reset();
        // this.searchByPersonForm.controls.nameSearchType.setValue(3);
        this.searchByPersonForm.controls.personInd.setValue(true);
        this.clearResults.emit();
    }

    searchPerson() {

        if (this.isSearchEmpty()) {
            this.notificationMessage.emit('An empty search can not be processed. One or more fields are required for this search.');
            return;
        }

        this.searchByPersonForm.markAsTouched();

        if (this.searchByPersonForm.valid) {
            this.searchRequest.emit(this.searchByPersonForm.value);
        }
    }

    private isSearchEmpty(): boolean {
        if ( isEmpty(this.searchByPersonForm.controls.FirstName.value) &&
        isEmpty(this.searchByPersonForm.controls.LastName.value)  &&
        isEmpty(this.searchByPersonForm.controls.MiddleName.value)
        )
        {
            return true;
        } else        {
            return false;
        }
    }
}
