export class relationship {
    relationshipId : number;
  
    constructor(
      public displayName: string,
      public personInd: boolean,
      public entityRegNumber: string,
      public entityRegName?: string,
      public physicalAddress?: string,
      public effectiveDate?: Date,
      public relationshipStartDate?: Date,
      public relationshipTypeId?: number,
      public relationshipTypeDesc?: string,
      public relationshipToEntityId?: number

    ) {}
  
   
  }