<mat-table *ngIf="gridprivateFundings && gridprivateFundings.length > 0; else emptyGrid" [dataSource]="dataSource"
    [attr.aria-label]="'companyRelationshipsGridComponent.tableDesc' | translate" matSort>

    <ng-container matColumnDef="privateFundingName">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell class="d-block d-lg-inline-block flex-lg-row mx-2 mb-2 m-lg-0" *matCellDef="let row"
            [attr.aria-label]="row.privateFundingName">
            <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">{{
                'forms.lobbyist.labels.nameOfEntityOrganizationOrIndividual' | translate }}</h3>
            <p class="cell-content d-inline-block d-lg-block pr-lg-2" replaceEmpty>{{ row.privateFundingName }}</p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="businessAddress">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell class="d-block d-lg-inline-block flex-lg-row mx-2 mb-2 m-lg-0" *matCellDef="let row"
        [attr.aria-label]="row.businessAddress">
            <h3 class="rs-field-label d-inline-block d-lg-block mr-1 mr-lg-0">{{
                'forms.lobbyist.labels.businessAddress' | translate }}</h3>
            <p class="cell-content d-inline-block d-lg-block pr-lg-2" replaceEmpty>{{ row.businessAddress }}
            </p>
        </mat-cell>
    </ng-container>



    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row class="d-block d-lg-flex flex-lg-row p-2 py-3 mt-2 p-lg-3 mt-lg-3"
        *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>

<mat-paginator #paginator [class.d-none]="gridprivateFundings?.length < 5" class="rs-regular-text mt-2 mt-lg-3"
    [length]="gridprivateFundings?.length" [pageIndex]="0" [pageSize]="5" (page)="handlePaging($event)">
</mat-paginator>

<ng-template #emptyGrid>
    <div class="empty">
        <div class="title">{{ 'forms.lobbyist.labels.noprivateFundings' | translate }}</div>
    </div>
</ng-template>