import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RegSysPaginatorIntlService extends MatPaginatorIntl {

    constructor(private translateService: TranslateService) {
        super();

        translateService.onLangChange.subscribe(res => {
            this.getIntlLabels();
        });

        this.getIntlLabels();
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        let label = '';
        const start = page * pageSize;
        const end = start < length ? Math.min(start + pageSize, length) : start + pageSize;

        this.translateService.get('paginatorComponent.of').subscribe(res => {
            label = `${start + 1} - ${end} ${res} ${length}`;
        });
        return label;
    }

    private getIntlLabels() {
        this.translateService.get(['paginatorComponent.itemsPerPage',
        'paginatorComponent.nextPage', 'paginatorComponent.prevPage']).subscribe(res => {
            this.itemsPerPageLabel = res['paginatorComponent.itemsPerPage'];
            this.nextPageLabel = res['paginatorComponent.nextPage'];
            this.previousPageLabel = res['paginatorComponent.prevPage'];
            this.changes.next();
        });
    }
}
