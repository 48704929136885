import { Directive, ElementRef, Input, ViewContainerRef, TemplateRef, ChangeDetectorRef } from '@angular/core';

import { CustomerUser, AuthService, SecurityEvent } from '@regsys/security';
import { environment } from '../../environments/environment';

@Directive({ selector: '[isGovernmentCustomerType]' })
export class IsGovernmentCustomerTypeDirective {

  condition: boolean;
  isLoggedIn: boolean;
  isElementShown = false;

  @Input() set isGovernmentCustomerType(condition: boolean) {
    // this.condition = condition;
    this.isLoggedIn = this.authService.isLoggedIn();
    const loggedCustomer: CustomerUser = this.authService.getAuthCustomerUser();
    if (loggedCustomer != null) {
      if (environment.governmentCustomerTypeId.indexOf(loggedCustomer.customerTypeId) !== -1) {
        this.condition = true;
      } else {
        this.condition = false;
      }
    } else {
      this.condition = false;
    }

  }

  constructor(
    private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef,
    private authService: AuthService, securityEvent: SecurityEvent, private cdr: ChangeDetectorRef) {
    securityEvent.loggedIn.subscribe(res => {
      this.isLoggedIn = res;
      const loggedCustomer2: CustomerUser = this.authService.getAuthCustomerUser();
      if (loggedCustomer2 != null) {
        if (environment.governmentCustomerTypeId.indexOf(loggedCustomer2.customerTypeId) !== -1) {
          this.condition = true;
          this.checkPrivileges();
        } else {
          this.condition = false;
          this.checkPrivileges();
        }
      } else {
        this.condition = false;
        this.checkPrivileges();
      }
    });

    securityEvent.tokenRefreshed.subscribe(res => {
      this.checkPrivileges();
    });
  }

  private checkPrivileges() {
    const removeElement = !this.isLoggedIn || !this.condition;
    if (removeElement) {
      this.viewContainer.clear();
      this.isElementShown = false;
    } else {
      if (!this.isElementShown) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isElementShown = true;
        this.cdr.detectChanges();
      }
    }
  }


}

