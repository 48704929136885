import { Component, Input, ViewChild, SimpleChange, OnChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { EntityRelationship } from '@regsys/web/lib/e-commerce/models/catalog/entity-relationship.model';
import { RegSysDataSource, RegsysWebConfig } from '@regsys/web';
import { SortGrid } from '../../../shared/functions/sort/src/sort-grid.function';
import { lobbyingActivity } from '../../../../models/lobbying-activity.model';



@Component({
    selector: 'regsys-lobbying-activity',
    templateUrl: './entity-lobbying-activity.component.html'
})
export class EntityLobbyingActivityComponent implements OnChanges {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) gridSort: MatSort;

    @Input() lobbyingActivities: lobbyingActivity[];
    @Input() hideActivityPeriod:boolean ;

    gridlobbyingActivities: lobbyingActivity[];
    displayedColumns = ['activityPeriod','undertakingName', 'subjectMatters', 'targets', 'communicationTech'];
    dataSource: RegSysDataSource;

    private formatting: any;

    constructor(regsysConfig: RegsysWebConfig) {
        this.formatting = regsysConfig.config.formattingOptions;
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if (changes.lobbyingActivities) {
            if (this.paginator) {
                this.paginator.pageIndex = 0;
            }

            this.gridlobbyingActivities = SortGrid({ active: 'attributeItemId', direction: 'asc' }, this.lobbyingActivities, this.formatting);
            this.dataSource = new RegSysDataSource(this.gridlobbyingActivities, this.paginator, this.gridSort);
            if(this.hideActivityPeriod) {
                this.displayedColumns = [ 'undertakingName', 'subjectMatters', 'targets', 'communicationTech', 'MLAswith'];
            }
        }
    }

    handlePaging(event: any) {
        // TODO: Find a better way to do this
        this.dataSource = new RegSysDataSource(this.gridlobbyingActivities, this.paginator, this.gridSort);
    }
}
