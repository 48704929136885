import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// @Regsys Packages
import { LocalizationEvent } from '@regsys/common';
import { AuthService, CustomerUser } from '@regsys/security';
import { StartNewFilingPanelService, EnquiryStatus, FormStatus } from '@regsys/web';

// Models
import { StatisticsOverviewTypes } from '../../models/statistics-overview-types.model';

// Projects can override these configurations
const graphSliceColors: any = {
  graphSubmissionStatus2: '#06E5FF',
  graphSubmissionStatus3: '#FCB756',
  graphSubmissionStatus4: '#5343e9',
  graphSubmissionStatus5: '#F05B72',
  graphSubmissionStatus6: '#6FD957',
  graphSubmissionStatus7: '#dc3545',
  graphSubmissionStatus8: '#0dafa9',
  graphSubmissionStatus9: '#0d8eaf'
};

@Component({
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  displayMyEntities = true;
  loggedUser: CustomerUser;

  colorScheme = {
    domain: [
        graphSliceColors.graphSubmissionStatus2,
        graphSliceColors.graphSubmissionStatus9,
        graphSliceColors.graphSubmissionStatus8,
        graphSliceColors.graphSubmissionStatus3,
        graphSliceColors.graphSubmissionStatus4,
        graphSliceColors.graphSubmissionStatus5,
        graphSliceColors.graphSubmissionStatus6,
        graphSliceColors.graphSubmissionStatus7
    ]
  };

  actionsList = [];

  constructor(
    public startNewFilingPanelService: StartNewFilingPanelService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private localizationEventService: LocalizationEvent
  ) {}

  ngOnInit() {
    this.loggedUser = this.authService.getAuthCustomerUser();

    this.route.params.forEach(param => {
      if (param.lang) {
        if (this.translateService.langs.includes(param.lang) && this.translateService.currentLang !== param.lang) {
          this.localizationEventService.languageChanged.publish(param.lang);
        }
      }
    });
  }

  routingHandler(link: string, prefix: string = ''): void {
    this.router.navigate([`${prefix}${link}`]);
    }

     goToSearch() {
         this.router.navigate([`search`]);
    }
}
