<div class="rs-container rs-account-details rs-content rs-container-border">
	
	<div class="rs-form-content">
		<form [formGroup]="searchByPersonForm" class="rs-container">
			<fieldset class="rs-container">
				<div class="fieldset-container">
	
				
	
					<div  class="inputs-wrapper">
						<div class="rs-container rs-input-block">
							<table style="width: 100%;"><tr>
								<td>
								<label class="rs-field-label" for="FirstName">
									{{ 'forms.rjsc.entity.firstName' | translate }}
								</label>
								<div class="input-wrapper">
									<input type="text" id="FirstName" background-focus formControlName="FirstName"
										class="field rs-input-text">
								</div>

							</td>
								
								<td><label class="rs-field-label" for="MiddleName">
									{{ 'forms.rjsc.entity.middleName' | translate }}
								</label>
								<div class="input-wrapper">
									<input type="text" id="MiddleName" background-focus formControlName="MiddleName"
										class="field rs-input-text">
								</div></td>

								<td><label class="rs-field-label" for="LastName">
									{{ 'forms.rjsc.entity.lastName' | translate }}
								</label>
								<div class="input-wrapper">
									<input type="text" id="LastName" background-focus formControlName="LastName"
										class="field rs-input-text">
								</div></td>
							</tr></table>
							
						</div>
						
					</div>
					

					<div  class="inputs-wrapper">
						<div class="rs-container rs-input-block">
						
						</div>
					</div>
					
				</div>
			</fieldset>
		</form>
	</div>
	
	<div class="d-flex flex-row align-items-center justify-content-between">
		<div class="justify-content-start">
			<button class="rs-button btn rs-secondary-button"
				(click)="reset()">{{ 'PersonSearchComponent.clear' | translate }}</button>
		</div>
		<div class="justify-content-end">
			<regsys-reactive-button [buttonText]="'PersonSearchComponent.btnSearch'" iconClass="rs-submit"
				[loadingMode]="isSearching" (click)="searchPerson()"></regsys-reactive-button>
		</div>
	</div>

</div>