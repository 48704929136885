import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { of, combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';
import isNil from 'lodash-es/isNil';

import { NotificationAggregator, ErrorNotification } from '@regsys/notifications';
import { CustomizationContainerDirective } from '@regsys/common';
import { AuthService } from '@regsys/security';

import { EntityService } from '../../services/entity.service';


import { Address } from '../../models/address.model'; 
import { Entity } from '../../models/entity.model';

import { EntityEvent } from '@regsys/web/lib/e-commerce/models/catalog/entity-event.model';

import { EntityRelationship } from '@regsys/web/lib/e-commerce/models/catalog/entity-relationship.model';

import { IRegsysWebConfig } from '@regsys/web/lib/shared/models/regsys_web.config';
import { RegsysWebConfig }  from '@regsys/web';
import { NAMED_ENTITIES } from '@angular/compiler';

import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { relationship } from '../../models/relationship.model';
import { lobbyingActivity } from '../../models/lobbying-activity.model';
import { governmentFunding } from '../../models/government-funding.model';
import { privateFunding } from '../../models/private-funding.model';


@Component({
    templateUrl: './lob.catalog.component.html'
})
export class LobCatalogComponent {

    @ViewChild(CustomizationContainerDirective, { static: true }) customizationContainer: CustomizationContainerDirective;

    displayProductsTab = true;
    entity: Entity;
    lobbyistEmployer: relationship;
    lobbyistClient: relationship;
    entityLatestEvents: EntityEvent[];
    entityOtherAddresses: Address[];
    entityRelationships: EntityRelationship[];
    lobbyingActivities:lobbyingActivity[];
    hideActivityPeriod : boolean = false;
    governmentFundings:governmentFunding[];
    privateFundings:privateFunding[];
    entityNames: any[];
    isLoadFinished: boolean;
    isShowingCustomActions: boolean;

    private config: IRegsysWebConfig;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private entityService: EntityService,
        private regsysConfig: RegsysWebConfig,
        private notificationService: NotificationAggregator,
        private componentFactoryResolver: ComponentFactoryResolver,
        private authService: AuthService
    ) {
        this.config = this.regsysConfig.config;
        this.route.params.subscribe((parameters: { id: number }) => {
            this.entity = null;
            this.entityLatestEvents = null;
            this.entityOtherAddresses = null;
            this.entityRelationships = null;
            this.entityNames = null;
            this.loadEntityDetails(+parameters.id);
            this.loadCustomComponents();
        });
    }

    displayProductsTabHandler(event: boolean) {
        this.displayProductsTab = event;
    }

    private loadCustomComponents(): void {
        if (this.config.eCommerce && this.config.eCommerce.customizedComponents) {
            const viewContainerRef: ViewContainerRef = this.customizationContainer.viewContainerRef;
            viewContainerRef.clear();
            this.config.eCommerce.customizedComponents.forEach(customArea => {
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(customArea);
                viewContainerRef.createComponent(componentFactory);
            });
        }
    }

    private loadEntityDetails(entityId: number): void {
        this.entityService.getEntityDetails(entityId).subscribe(entity => {
            if (isNil(entity)) {
                this.notificationService.publish(new ErrorNotification('CatalogComponent.unableToLoadEntityDetails'));
                return this.router.navigate(['/dashboard']);
            }

            if (entity.personInd) {
                return this.router.navigate([`persons/${entity.cleanURL()}`]);
            }

            this.entity = entity;
            this.loadEntityExtraData();
            this.customActionsHandler();
        });
    }

    private customActionsHandler() {
        if (this.authService.isLoggedIn()) {
            this.entityService.getMyEntities()
                .subscribe((result) => {
                    if (result.data && result.data.some(ent => ent.entityId === this.entity.entityId)) {
                        this.isShowingCustomActions = true;
                    }
                });
        }
    }

    private loadEntityExtraData(): void {

        this.entityService.getEntityRelationships(this.entity.entityId).subscribe(relationships => {
            if (!relationships.success) {
                this.notificationService.publish(new ErrorNotification('CatalogComponent.unableToLoadEntityRelationships'));
            }

            this.entityRelationships = relationships.data;
        });

        this.entityService.getLobbyingActivityList(this.entity.entityId).subscribe(lobActivites => {
            if (!lobActivites.success) {
                this.notificationService.publish(new ErrorNotification('forms.lobbyist.labels.unableToLoadEntityLobbyingActivity'));
            }

            this.lobbyingActivities = lobActivites.data;
            if(this.entity.entityTypeId == 302) {
                this.hideActivityPeriod = false;                
            }
            else {
                this.hideActivityPeriod = true;
            }

        });


        this.entityService.getgovernmentFundingList(this.entity.entityId).subscribe(govFundings => {
            if (!govFundings.success) {
                this.notificationService.publish(new ErrorNotification('forms.lobbyist.labels.unableToLoadEntityGovernmentFunding'));
            }
           
            this.governmentFundings = govFundings.data;
        });


        this.entityService.getprivateFundingList(this.entity.entityId).subscribe(prvtFundings => {
            if (!prvtFundings.success) {
                this.notificationService.publish(new ErrorNotification('forms.lobbyist.labels.unableToLoadEntityPrivateFunding'));
            }

            this.privateFundings = prvtFundings.data;
        });

        this.entityService.getLobbyistEmployer(this.entity.entityId).subscribe(lobEmployer => {
            if (!lobEmployer.success) {
                this.notificationService.publish(new ErrorNotification('forms.lobbyist.labels.unableToLoadEntityLobbyistEmployer'));
            }

            this.lobbyistEmployer = lobEmployer.data;
        });


        this.entityService.getLobbyistClient(this.entity.entityId).subscribe(lobClient => {
            if (!lobClient.success) {
                this.notificationService.publish(new ErrorNotification('forms.lobbyist.labels.unableToLoadEntityLobbyistClient'));
            }

            this.lobbyistClient = lobClient.data;
        });

        this.entityService.getLatestEvents(this.entity.entityId, 5).subscribe(latestEvents => {
            if (!latestEvents.success) {
                this.notificationService.publish(new ErrorNotification('CatalogComponent.unableToLoadLatestEvents'));
            }
            this.entityLatestEvents = latestEvents.data;
        });

        this.entityService.getOtherAddresses(this.entity.entityId).subscribe(otherAddresses => this.entityOtherAddresses = otherAddresses);

        this.entityService.getEntityNames(this.entity.entityId).subscribe(entityNames => {
            if (!entityNames.success) {
                this.notificationService.publish(new ErrorNotification('CatalogComponent.unableToLoadNames'));
            }

            this.entityNames = entityNames.data;
        });
    }
}
