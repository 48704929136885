<div class="rs-root">
  <regsys-notification aria-live="polite"></regsys-notification>

  <regsys-header [isLeftPanelCollapsed]="isLeftPanelCollapsed" (leftPanelChanged)="leftPanelChangedHandler($event)"></regsys-header>

  <regsys-start-new-filing-panel class="rs-panel"></regsys-start-new-filing-panel>
  
  <div class="rs-content-wrapper">
    <regsys-nav [isLeftPanelCollapsed]="isLeftPanelCollapsed" (leftPanelChanged)="leftPanelChangedHandler($event)"></regsys-nav>

    <div class="rs-main-wrapper" [class.collapsed]="!isLeftPanelCollapsed">
      <main id="content">
        <router-outlet></router-outlet>
      </main>
      <regsys-footer></regsys-footer>
    </div>
  </div>
</div>