
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { QuestionBase, CallbackProcessor, RegSysFormControl } from '@regsys/forms';
import { FindControl } from '@regsys/common';
import { RegSysHttpModule, RegsysHttp, PostRequest, GetRequest } from '@regsys/http';

import * as FileSaver from 'file-saver';
import { replace } from 'lodash';
import { COOPbyLawsReportParam } from '../../../app/models/COOPbyLawsReportParam';
import { SubscriberItem } from '../../../app/models/SubscriberItem';
import { byLawsReportParam } from '../../../app/models/byLawsReportParam';
import { SubscribersReportParam } from '../../../app/models/SubscribersReportParam';
import { MemorandumReportParam } from '../../../app/models/memorandumReportParam';
import { environment } from '../../../environments/environment';
let regsysHttpSuper = null;
let legalEnding = '';

@Injectable()
export class CustomCallbackService extends CallbackProcessor {

    constructor(private regsysHttp: RegsysHttp) {
        super();
        regsysHttpSuper = regsysHttp;
    }
    // Customized system Callbacks implementations
    clone(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { valueToCopyTo: string }) {
        const controlToCopyFrom: FormControl = FindControl(form, questionTriggering.key);
        const controlToCopyTo: FormControl = FindControl(form, replace(parameters.valueToCopyTo, '@', ''));

        controlToCopyTo.setValue(controlToCopyFrom.value);
    }


    validateText(questionTriggering: QuestionBase<any>, form: FormGroup) {

        if (questionTriggering.control.value === 'duplicated') {
            questionTriggering.control.markAsTouched();
            addErrors({ validateText: { valid: false, message: 'Duplicated Text' } }, questionTriggering.control);
        } else {
            removeErrors(['validateText'], questionTriggering.control);
        }
    }


    sum(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { arrayToSum: string[], copyTo: string }) {
        let sum = 0;
        parameters.arrayToSum.forEach((key: string) => {
            const control: FormControl = FindControl(form, replace(key, '@', ''));
            if (control && control.value > 0) {
                sum += Number(control.value);
            }
        });

        const controlToCopyTo: FormControl = FindControl(form, replace(parameters.copyTo, '@', ''));

        controlToCopyTo.setValue(sum);
    }

    sumList(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { valueToSum: string, copyTo: string }) {
        const controlToCopyFrom: FormArray = FindControl(form, questionTriggering.key);

        let sum = 0;
        if (controlToCopyFrom.controls && controlToCopyFrom.controls.length > 0) {
            controlToCopyFrom.controls.forEach(control => {
                const controlToSum: FormControl = FindControl(control, replace(parameters.valueToSum, '@', ''));
                if (controlToSum) {
                    sum += Number(controlToSum.value);
                }
            });
        }

        const controlToCopyTo: FormControl = FindControl(form, replace(parameters.copyTo, '@', ''));

        controlToCopyTo.setValue(sum);
    }

    listLength(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { copyTo: string }) {
        const controlToCheck: FormArray = FindControl(form, questionTriggering.key);

        const controlToCopyTo: FormControl = FindControl(form, replace(parameters.copyTo, '@', ''));

        controlToCopyTo.setValue(controlToCheck.controls.length);
    }

    CopyValue(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { CopyFrom: string, CopyTo: string, ConditionalCtrl: string, ConditionValues: string[] }) {
        const controlCopyFrom: FormControl = FindControl(form, replace(parameters.CopyFrom, '@', ''));
        const controlCopyTo: FormControl = FindControl(form, replace(parameters.CopyTo, '@', ''));
        const ConditionalControl: FormControl = FindControl(form, replace(parameters.ConditionalCtrl, '@', ''));

        if (parameters.ConditionValues.includes(ConditionalControl.value)) {
            controlCopyTo.setValue(controlCopyFrom.value);
        }

    }

    CopyValueMulti(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { CopyFrom: string, CopyTo: string, ConditionalCtrl1: string, ConditionValues1: string[], ConditionalCtrl2: string, ConditionValues2: string[] }) {
        const controlCopyFrom: FormControl = FindControl(form, replace(parameters.CopyFrom, '@', ''));
        const controlCopyTo: FormControl = FindControl(form, replace(parameters.CopyTo, '@', ''));
        const ConditionalControl1: FormControl = FindControl(form, replace(parameters.ConditionalCtrl1, '@', ''));
        const ConditionalControl2: FormControl = FindControl(form, replace(parameters.ConditionalCtrl2, '@', ''));

        if (parameters.ConditionValues1.includes(ConditionalControl1.value) && parameters.ConditionValues2.includes(ConditionalControl2.value)) {
            controlCopyTo.setValue(controlCopyFrom.value);
        }

    }

    PopulateFullName(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { CopyFrom: string, CopyTo: string, LegalEnding: string, type: string }) {
        const controlCopyFrom: FormControl = FindControl(form, replace(parameters.CopyFrom, '@', ''));
        const controlCopyTo: FormControl = FindControl(form, replace(parameters.CopyTo, '@', ''));
        const LegalEndingControl: FormControl = FindControl(form, replace(parameters.LegalEnding, '@', ''));

        if (parameters.type.toUpperCase() == 'POPNAME') {
            if (questionTriggering.options) {
                let selectedLegalEnding = questionTriggering.options.filter(function (legal) {
                    return legal.key == LegalEndingControl.value;
                });
                if (selectedLegalEnding[0]) {
                    legalEnding = selectedLegalEnding[0].value;
                } else {
                    legalEnding = '';
                }
            }
        } else if (parameters.type.toUpperCase() == 'EEPOPNAME') {
            legalEnding = LegalEndingControl.value;
        }

        let RegName: string = controlCopyFrom.value;

        RegName = RegName + ' ' + legalEnding;
        controlCopyTo.setValue(RegName.toUpperCase());
    }

    PopulateFullNameLobbyist(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { CopyTo: string, FName: string, MName: string, LName: string }) {

        const controlCopyTo: FormControl = FindControl(form, replace(parameters.CopyTo, '@', ''));
        const controlFName: FormControl = FindControl(form, replace(parameters.FName, '@', ''));
        const controlMName: FormControl = FindControl(form, replace(parameters.MName, '@', ''));
        const ControlLName: FormControl = FindControl(form, replace(parameters.LName, '@', ''));

        var RegName: string = '';
        if (controlMName.value != '' && controlMName.value != null) {
            RegName = controlFName.value + ' ' + controlMName.value + ' ' + ControlLName.value;
        } else {
            RegName = controlFName.value + ' ' + ControlLName.value;
        }
        controlCopyTo.setValue(RegName.toUpperCase());
    }

    setVisibleOnOther(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { listField: string, otherField: string, visibleField: string, replacingField: string, showSelectedItemField: string, parameterValue: string }) {
        const list: FormControl = FindControl(form, replace(parameters.listField, '@', ''));
        const other: FormControl = FindControl(form, replace(parameters.otherField, '@', ''));
        const visible: FormControl = FindControl(form, replace(parameters.visibleField, '@', ''));
        const ctrlSelectedItmes: FormControl = FindControl(form, replace(parameters.showSelectedItemField, '@', ''));

        var selectedList: string = "";
        var tempVisible: boolean = false;
        let temp: string = replace(parameters.replacingField, '@', '');

        if (list.value instanceof Array) {
            list.value.forEach((itm) => {
                let itmStr: string = JSON.stringify(itm).replace('"' + temp + '":', '').replace('{{', '{').replace('}}', '}');

                let JSONItems = JSON.parse(itmStr, (k, v) => {
                    if (v == parameters.parameterValue) {
                        tempVisible = true;
                    }
                    if (v != '[object Object]' && !Number.isInteger(v)) {
                        if (v == "Other")
                            selectedList += other.value + ", ";
                        else
                            selectedList += v + ", ";
                    }

                    return v;
                });
            })
        }

        visible.setValue(tempVisible);
        ctrlSelectedItmes.setValue(selectedList.substring(0, selectedList.length - 2));
    }

    setRoleIsAn(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { role: string, setField: string }) {
        const role: FormControl = FindControl(form, replace(parameters.role, '@', ''));
        const controlToCopyTo: FormControl = FindControl(form, replace(parameters.setField, '@', ''));



        if (role.value != null) {
            if (role.value.includes('3')) {
                controlToCopyTo.setValue(true);
            }
        }
    }

    SetReserveNamePurposeId(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { RegisterIdCtrl: string, EntityTypeIdCtrl: string, PurposeNameCtrl: string, SelectedPurposeIdCtrl: string, PurposeNameIdCtrl }) {
        const RegisterIdCtrl: FormControl = FindControl(form, replace(parameters.RegisterIdCtrl, '@', ''));
        const EntityTypeIdCtrl: FormControl = FindControl(form, replace(parameters.EntityTypeIdCtrl, '@', ''));
        const PurposeNameCtrl: FormControl = FindControl(form, replace(parameters.PurposeNameCtrl, '@', ''));
        const SelectedPurposeIdCtrl: FormControl = FindControl(form, replace(parameters.SelectedPurposeIdCtrl, '@', ''));
        const PurposeNameIdCtrl: FormControl = FindControl(form, replace(parameters.PurposeNameIdCtrl, '@', ''));
        PurposeNameIdCtrl.setValue("");

        if (SelectedPurposeIdCtrl) {
            if (SelectedPurposeIdCtrl.value != "undefined") {
                PurposeNameIdCtrl.setValue(SelectedPurposeIdCtrl.value);
                return;
            }
        }

        if (PurposeNameCtrl.value == '1') // Name Change
        {
            switch (EntityTypeIdCtrl.value) {
                case 111:
                    PurposeNameIdCtrl.setValue(20);
                    break;
                case 112:
                    PurposeNameIdCtrl.setValue(21);
                    break;
                case 113:
                    PurposeNameIdCtrl.setValue(22);
                    break;
                case 114:
                    PurposeNameIdCtrl.setValue(23);
                    break;

                case 115:
                    PurposeNameIdCtrl.setValue(86);
                    break;

                case 165:
                    PurposeNameIdCtrl.setValue(114);
                    break;

                case 166:
                    PurposeNameIdCtrl.setValue(115);
                    break;

                case 116:
                    PurposeNameIdCtrl.setValue(87);
                    break;

                case 117:
                    PurposeNameIdCtrl.setValue(88);
                    break;

                case 118:
                    PurposeNameIdCtrl.setValue(89);
                    break;

                case 119:
                    PurposeNameIdCtrl.setValue(90);
                    break;

                case 120:
                    PurposeNameIdCtrl.setValue(83);
                    break;

                case 121:
                    PurposeNameIdCtrl.setValue(84);
                    break;

                case 122:
                    PurposeNameIdCtrl.setValue(82);
                    break;

                case 123:
                    PurposeNameIdCtrl.setValue(85);
                    break;

                case 124:
                    PurposeNameIdCtrl.setValue(81);
                    break;

                case 125:
                    PurposeNameIdCtrl.setValue(68);
                    break;

                case 126:
                    PurposeNameIdCtrl.setValue(91);
                    break;

                case 128:
                    PurposeNameIdCtrl.setValue(71);
                    break;

                case 129:
                    PurposeNameIdCtrl.setValue(72);
                    break;

                case 130:
                    PurposeNameIdCtrl.setValue(73);
                    break;

                case 131:
                    PurposeNameIdCtrl.setValue(74);
                    break;

                case 132:
                    PurposeNameIdCtrl.setValue(75);
                    break;

                case 148:
                    PurposeNameIdCtrl.setValue(24);
                    break;
            }
        }
        else if (PurposeNameCtrl.value == '2')// Restoration
        {
            switch (EntityTypeIdCtrl.value) {

                case 111:
                    PurposeNameIdCtrl.setValue(32);
                    break;
                case 112:
                    PurposeNameIdCtrl.setValue(33);
                    break;
                case 113:
                    PurposeNameIdCtrl.setValue(34);
                    break;
                case 114:
                    PurposeNameIdCtrl.setValue(35);
                    break;
                case 148:
                    PurposeNameIdCtrl.setValue(36);
                    break;
                case 125:
                    PurposeNameIdCtrl.setValue(69);
                    break;
                case 115:
                    PurposeNameIdCtrl.setValue(103);
                    break;
                case 165:
                    PurposeNameIdCtrl.setValue(118);
                    break;
                case 166:
                    PurposeNameIdCtrl.setValue(119);
                    break;
                case 116:
                    PurposeNameIdCtrl.setValue(104);
                    break;
                case 117:
                    PurposeNameIdCtrl.setValue(105);
                    break;
                case 118:
                    PurposeNameIdCtrl.setValue(106);
                    break;
                case 119:
                    PurposeNameIdCtrl.setValue(107);
                    break;
                case 120:
                    PurposeNameIdCtrl.setValue(108);
                    break;
                case 121:
                    PurposeNameIdCtrl.setValue(109);
                    break;
                case 122:
                    PurposeNameIdCtrl.setValue(110);
                    break;
                case 126:
                    PurposeNameIdCtrl.setValue(111);
                    break;

            }

        }
        else if (PurposeNameCtrl.value == '3')// Reinstatement
        {
            switch (EntityTypeIdCtrl.value) {
                case 111:
                    PurposeNameIdCtrl.setValue(26);
                    break;
                case 112:
                    PurposeNameIdCtrl.setValue(27);
                    break;
                case 113:
                    PurposeNameIdCtrl.setValue(28);
                    break;
                case 114:
                    PurposeNameIdCtrl.setValue(29);
                    break;
                case 148:
                    PurposeNameIdCtrl.setValue(30);
                    break;
                case 124:
                    PurposeNameIdCtrl.setValue(92);
                    break;
                case 123:
                    PurposeNameIdCtrl.setValue(93);
                    break;
                case 115:
                    PurposeNameIdCtrl.setValue(94);
                    break;
                case 165:
                    PurposeNameIdCtrl.setValue(116);
                    break;
                case 166:
                    PurposeNameIdCtrl.setValue(117);
                    break;
                case 116:
                    PurposeNameIdCtrl.setValue(95);
                    break;
                case 117:
                    PurposeNameIdCtrl.setValue(96);
                    break;
                case 118:
                    PurposeNameIdCtrl.setValue(97);
                    break;
                case 119:
                    PurposeNameIdCtrl.setValue(98);
                    break;
                case 120:
                    PurposeNameIdCtrl.setValue(99);
                    break;
                case 121:
                    PurposeNameIdCtrl.setValue(100);
                    break;
                case 122:
                    PurposeNameIdCtrl.setValue(101);
                    break;
                case 126:
                    PurposeNameIdCtrl.setValue(102);
                    break;


            }
        }
        else if (PurposeNameCtrl.value == '4')// Conversion
        {
            switch (EntityTypeIdCtrl.value) {

                case 111:
                    PurposeNameIdCtrl.setValue(14);
                    break;
                case 112:
                    PurposeNameIdCtrl.setValue(15);
                    break;
                case 113:
                    PurposeNameIdCtrl.setValue(16);
                    break;
                case 114:
                    PurposeNameIdCtrl.setValue(17);
                    break;
                case 120:
                    PurposeNameIdCtrl.setValue(79);
                    break;
                case 121:
                    PurposeNameIdCtrl.setValue(78);
                    break;
                case 122:
                    PurposeNameIdCtrl.setValue(77);
                    break;
                case 123:
                    PurposeNameIdCtrl.setValue(76);
                    break;
                case 124:
                    PurposeNameIdCtrl.setValue(80);
                    break;
                case 147:
                    PurposeNameIdCtrl.setValue(13);
                    break;
                case 148:
                    PurposeNameIdCtrl.setValue(18);
                    break;
            }

        }

    }


    public generateSubscribersReport(questionTriggering: any, form: FormGroup, parameters: { keys: string[] }) {

        let params: any[] = [];
        let param: SubscribersReportParam = new SubscribersReportParam();
        parameters.keys.forEach((key) => {
            const controlToCopyTo: FormControl = FindControl(form, key.replace('@', ''));
            if (controlToCopyTo.value instanceof Array) {
                let SubscriberList: SubscriberItem[] = [];
                let Subscriber = new SubscriberItem();
                controlToCopyTo.value.forEach((itm) => {
                    Subscriber = new SubscriberItem();
                    let itmStr: string = JSON.stringify(itm);
                    itmStr = itmStr.replace('{"00000002-0011-0001-0001-000000000000":', '').replace('},"00000002-0011-0001-0002-000000000000":{', ',').replace('},"00000002-0011-0001-0003-000000000000":{', ',').replace('}}', '}');

                    let JSONItems = JSON.parse(itmStr, (k, v) => {
                        return v;
                    });
                    Subscriber.personInd = JSONItems['00000002-0011-0001-0001-000000000001'];
                    Subscriber.occupation = JSONItems['00000002-0011-0001-0001-000000000022'];
                    Subscriber.fees = JSONItems['00000002-0011-0001-0001-000000000023'];
                    Subscriber.shares = JSONItems['00000002-0011-0001-0001-000000000023'];
                    Subscriber.entityFirstName = JSONItems['00000002-0011-0001-0001-000000000013'];
                    Subscriber.entityMiddleName = JSONItems['00000002-0011-0001-0001-000000000014'];
                    Subscriber.entityLastName = JSONItems['00000002-0011-0001-0001-000000000015'];
                    Subscriber.regAddressLine1 = JSONItems['00000002-0011-0001-0002-000000000041'];
                    Subscriber.regAddressLine2 = JSONItems['00000002-0011-0001-0002-000000000042'];
                    Subscriber.regAddressLine3 = JSONItems['00000002-0011-0001-0002-000000000043'];
                    Subscriber.regAddressCity = JSONItems['00000002-0011-0001-0002-000000000044'];
                    Subscriber.regAddressCountryId = JSONItems['00000002-0011-0001-0002-000000000045'];
                    Subscriber.regAddressState = JSONItems['00000002-0011-0001-0002-000000000046'];
                    Subscriber.regAddressProvId = JSONItems['00000002-0011-0001-0002-000000000047'] || 0;
                    Subscriber.regAddressStateId = JSONItems['00000002-0011-0001-0002-000000000047'] || 0;
                    Subscriber.regAddressPostcode = JSONItems['00000002-0011-0001-0002-000000000049'];
                    Subscriber.entityRegNumber = JSONItems['00000002-0011-0001-0001-000000000011'];
                    Subscriber.entityRegName = JSONItems['00000002-0011-0001-0001-000000000012'];
                    Subscriber.regAddressOneLine = JSONItems['00000002-0011-0001-0001-000000000019'];
                    SubscriberList.push(Subscriber);
                })
                params.push(SubscriberList);
            }
            else {
                params.push(controlToCopyTo.value);
            }
        })

        param.entityRegName = params[0];
        param.entityRegNumber = params[1];
        param.limitedByType = params[2];
        param.subscribers = params[3];

        const request: PostRequest = {
            url: `${environment.apiUrl}rjscsubmission/subscriberspdf`,
            parameters: param,
            getFullResponse: true
        }

        regsysHttpSuper.doPost(request).subscribe((httpResponse: any) => {
            const filename = `Subscribers-${param.entityRegName} - ${param.entityRegNumber}.pdf`;
            // tslint:disable-next-line:prefer-const
            let raw = window.atob(httpResponse.data);
            const rawlength = raw.length;
            const array = new Uint8Array(new ArrayBuffer(rawlength));
            for (let i = 0; i <= rawlength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            const file = new Blob([array], { type: 'application/pdf' });
            FileSaver.saveAs(file, filename);
        })
    }

    public generateMemorandumReport(questionTriggering: any, form: FormGroup, parameters: { keys: string[] }) {

        const params: any[] = [];
        // tslint:disable-next-line:prefer-const
        let param: MemorandumReportParam = new MemorandumReportParam();
        parameters.keys.forEach((key) => {
            const controlToCopyTo: FormControl = FindControl(form, key.replace('@', ''));
            if (controlToCopyTo.value instanceof Array) {
                const SubscriberList: SubscriberItem[] = [];
                let Subscriber = new SubscriberItem();
                controlToCopyTo.value.forEach((itm) => {
                    Subscriber = new SubscriberItem();
                    let itmStr: string = JSON.stringify(itm);
                    itmStr = itmStr.replace('{"00000002-0003-0001-0001-000000000000":', '').replace('},"00000002-0003-0001-0002-000000000000":{', ',').replace('},"00000002-0003-0001-0003-000000000000":{', ',').replace('}}', '}');

                    const JSONItems = JSON.parse(itmStr, (k, v) => {
                        return v;
                    });
                    Subscriber.personInd = JSONItems['00000002-0003-0001-0001-000000000001'];
                    Subscriber.occupation = JSONItems['00000002-0003-0001-0001-000000000022'];
                    Subscriber.shares = JSONItems['00000002-0001-0001-0000-000000000032'];
                    Subscriber.entityFirstName = JSONItems['00000002-0003-0001-0001-000000000013'];

                    Subscriber.entityMiddleName = JSONItems['00000002-0003-0001-0001-000000000014'];
                    Subscriber.entityLastName = JSONItems['00000002-0003-0001-0001-000000000015'];
                    Subscriber.regAddressLine1 = JSONItems['00000002-0003-0001-0002-000000000041'];
                    Subscriber.regAddressLine2 = JSONItems['00000002-0003-0001-0002-000000000042'];
                    Subscriber.regAddressLine3 = JSONItems['00000002-0003-0001-0002-000000000043'];
                    Subscriber.regAddressCity = JSONItems['00000002-0003-0001-0002-000000000044'];
                    Subscriber.regAddressCountryId = JSONItems['00000002-0003-0001-0002-000000000045'];
                    Subscriber.regAddressState = JSONItems['00000002-0003-0001-0002-000000000046'];

                    Subscriber.regAddressStateId = JSONItems['00000002-0003-0001-0002-000000000047'] || 0;
                    Subscriber.regAddressProvId = JSONItems['00000002-0003-0001-0002-000000000047'] || 0;

                    Subscriber.regAddressPostcode = JSONItems['00000002-0003-0001-0002-000000000049'];
                    Subscriber.entityId = JSONItems['00000002-0001-0001-0000-000000000031'];
                    Subscriber.entityRegNumber = JSONItems['00000002-0003-0001-0001-000000000011'];
                    Subscriber.entityRegName = JSONItems['00000002-0003-0001-0001-000000000012'];
                    Subscriber.regAddressOneLine = JSONItems['00000002-0003-0001-0001-000000000019'];
                    SubscriberList.push(Subscriber);
                })
                params.push(SubscriberList);
            } else {
                params.push(controlToCopyTo.value);
            }
        });

        param.registerId = params[0];
        param.registerDesc = params[1];
        param.entityTypeId = params[2];
        param.entityTypeDesc = params[3];
        param.entityRegDate = params[4];
        param.entityRegName = params[5];
        param.entityRegNumber = params[6];
        param.subscribers = params[7];
        param.objectives = params[8];
        param.dissolution = params[9] || 0;
        param.geographicArea = params[10];
        param.addressLine1 = params[11];
        param.addressLine2 = params[12];
        param.addressLine3 = params[13];
        param.city = params[14];
        param.postCode = params[15];

        const request: PostRequest = {
            url: `${environment.apiUrl}rjscsubmission/memorandumpdf`,
            parameters: param,
            getFullResponse: true
        }

        if (param.subscribers.length == 0) {
            alert('At least one subscriber should be added');
        } else {

            regsysHttpSuper.doPost(request).subscribe((httpResponse: any) => {
                const filename = `Memorandum-${param.entityRegName} - ${param.entityRegNumber}.pdf`;
                const raw = window.atob(httpResponse.data);
                var rawlength = raw.length;
                var array = new Uint8Array(new ArrayBuffer(rawlength));
                for (var i = 0; i <= rawlength; i++) {
                    array[i] = raw.charCodeAt(i);
                }
                let file = new Blob([array], { type: 'application/pdf' });
                FileSaver.saveAs(file, filename);
            })
        }


    }
    public generateByLawsReport(questionTriggering: any, form: FormGroup, parameters: { keys: string[] }) {
        var params: any[] = [];
        var param: byLawsReportParam = new byLawsReportParam();
        parameters.keys.forEach((key) => {
            const controlToCopyTo: FormControl = FindControl(form, key.replace('@', ''));
            params.push(controlToCopyTo.value);
        })

        param.entityRegName = params[0];
        param.entityRegNumber = params[1];
        const request: PostRequest = {
            url: `${environment.apiUrl}rjscsubmission/bylawspdf`,
            parameters: param,
            getFullResponse: true
        }
        regsysHttpSuper.doPost(request).subscribe((httpResponse: any) => {
            let filename = `ByLaws-${param.entityRegName} - ${param.entityRegNumber}.pdf`
            var raw = window.atob(httpResponse.data);
            var rawlength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawlength));
            for (var i = 0; i <= rawlength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            let file = new Blob([array], { type: 'application/pdf' });
            FileSaver.saveAs(file, filename);
        })
    }
    public generateCOOPByLawsReport(questionTriggering: any, form: FormGroup, parameters: { keys: string[] }) {
        var params: any[] = [];
        var param: COOPbyLawsReportParam = new COOPbyLawsReportParam();
        parameters.keys.forEach((key) => {
            const controlToCopyTo: FormControl = FindControl(form, key.replace('@', ''));
            if (controlToCopyTo.value instanceof Array) {
                var SubscriberList: SubscriberItem[] = [];
                let Subscriber = new SubscriberItem();
                controlToCopyTo.value.forEach((itm) => {
                    Subscriber = new SubscriberItem();
                    var itmStr: string = JSON.stringify(itm);
                    itmStr = itmStr.replace('{"00000002-0011-0001-0001-000000000000":', '').replace('},"00000002-0011-0001-0002-000000000000":{', ',').replace('},"00000002-0011-0001-0003-000000000000":{', ',').replace('}}', '}');

                    var JSONItems = JSON.parse(itmStr, (k, v) => {
                        return v;
                    });
                    Subscriber.personInd = JSONItems['00000002-0011-0001-0001-000000000001'];
                    Subscriber.occupation = JSONItems['00000002-0011-0001-0001-000000000022'];
                    Subscriber.fees = JSONItems['00000002-0011-0001-0001-000000000023'];
                    Subscriber.shares = JSONItems['00000002-0011-0001-0001-000000000023'];
                    Subscriber.entityFirstName = JSONItems['00000002-0011-0001-0001-000000000013'];
                    Subscriber.entityMiddleName = JSONItems['00000002-0011-0001-0001-000000000014'];
                    Subscriber.entityLastName = JSONItems['00000002-0011-0001-0001-000000000015'];
                    Subscriber.regAddressLine1 = JSONItems['00000002-0011-0001-0002-000000000041'];
                    Subscriber.regAddressLine2 = JSONItems['00000002-0011-0001-0002-000000000042'];
                    Subscriber.regAddressLine3 = JSONItems['00000002-0011-0001-0002-000000000043'];
                    Subscriber.regAddressCity = JSONItems['00000002-0011-0001-0002-000000000044'];
                    Subscriber.regAddressCountryId = JSONItems['00000002-0011-0001-0002-000000000045'];
                    Subscriber.regAddressState = JSONItems['00000002-0011-0001-0002-000000000046'];
                    Subscriber.regAddressProvId = JSONItems['00000002-0011-0001-0002-000000000047'] || 0;
                    Subscriber.regAddressStateId = JSONItems['00000002-0011-0001-0002-000000000047'] || 0;
                    Subscriber.regAddressPostcode = JSONItems['00000002-0011-0001-0002-000000000049'];
                    Subscriber.entityRegNumber = JSONItems['00000002-0011-0001-0001-000000000011'];
                    Subscriber.entityRegName = JSONItems['00000002-0011-0001-0001-000000000012'];
                    Subscriber.regAddressOneLine = JSONItems['00000002-0011-0001-0001-000000000019'];
                    SubscriberList.push(Subscriber);
                })
                params.push(SubscriberList);
            }
            else {
                params.push(controlToCopyTo.value);
            }
        })

        param.entityRegName = params[0];
        param.entityRegNumber = params[1];
        param.objective = params[2];
        param.addressLine1 = params[3];
        param.addressLine2 = params[4];
        param.addressLine3 = params[5];
        param.city = params[6];
        param.postCode = params[7];
        param.fiscalYearEnd = params[8] || 0;
        param.memberQuorumType = params[9] || 0;
        param.memberQuorumNumber = params[10] || 0;
        param.memberQuorumPercent = params[11] || 0;
        param.minDir = params[12] || 0;
        param.maxDir = params[13] || 0;
        param.termYears = params[14] || 0;
        param.maxTerm = params[15] || 0;
        param.dirMeetingTimes = params[16] || 0;
        param.dirQuorumType = params[17] || 0;
        param.dirQuorumNumber = params[18] || 0;
        param.dirQuorumPercent = params[19] || 0;
        param.membershipFee = params[20] || 0;
        param.memberLoans = params[21] || false;
        param.maxMemberLoans = params[22] || 0;
        param.borrowingAmount = params[23] || 0;
        param.coopType = params[24];
        param.limitedBy = params[25];
        param.shareValue = params[26] || "";
        param.minNumShares = params[27] || 0;
        param.genReservePercent = params[28];
        param.genReserveSurplus = params[29];
        param.subscribers = params[30];

        const request: PostRequest = {
            url: `${environment.apiUrl}rjscsubmission/coopbylawspdf`,
            parameters: param,
            getFullResponse: true
        }
        if (param.subscribers.length < 3) {
            alert('At least three subscribers should be added');
        } else {

            regsysHttpSuper.doPost(request).subscribe((httpResponse: any) => {
                let filename = `COOPByLaws-${param.entityRegName} - ${param.entityRegNumber}.pdf`
                var raw = window.atob(httpResponse.data);
                var rawlength = raw.length;
                var array = new Uint8Array(new ArrayBuffer(rawlength));
                for (var i = 0; i <= rawlength; i++) {
                    array[i] = raw.charCodeAt(i);
                }
                let file = new Blob([array], { type: 'application/pdf' });
                FileSaver.saveAs(file, filename);
            })
        }
    }
    setHJCountry(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { country: string, copyTo: string, stateId: string, stateName: string, homeJuris: string }) {
        var controlCountry: RegSysFormControl = FindControl(form, replace(parameters.country, '@', ''));
        const controlToCopyTo: FormControl = FindControl(form, replace(parameters.copyTo, '@', ''));
        const controlStateId: FormControl = FindControl(form, replace(parameters.stateId, '@', ''));
        const controlStateName: FormControl = FindControl(form, replace(parameters.stateName, '@', ''));
        const controlHomeJuris: FormControl = FindControl(form, replace(parameters.homeJuris, '@', ''));

        //For EP, EP Financial, EP Insurance, EPLP, EPLLP
        if (controlHomeJuris.value == 173 || controlHomeJuris.value == 280 || controlHomeJuris.value == 281 || controlHomeJuris.value == 446 || controlHomeJuris.value == 1 || controlHomeJuris.value == 2) {
            //Means Country is Canada
            if (questionTriggering.key == replace(parameters.homeJuris, '@', '')) {
                controlToCopyTo.setValue(38);
                controlStateName.setValue(null);
                controlCountry.setValue(null);
            }
        }
        else {
            controlToCopyTo.setValue(controlCountry.value);

            if (controlCountry.value == 227) {
                controlStateName.setValue(null);
            }
            else {
                controlStateId.setValue(null);
            }
        }
    }

    //Called from 124-EP Reg, 137-EP Amal 
    setHJState(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { homeJuris: string, country: string, statId: string, stateName: string, hjStatId: string, hjStateName: string }) {
        const controlHomeJuris: FormControl = FindControl(form, replace(parameters.homeJuris, '@', ''));
        const controlCountry: FormControl = FindControl(form, replace(parameters.country, '@', ''));
        const controlStateId: FormControl = FindControl(form, replace(parameters.statId, '@', ''));
        const controlStateName: FormControl = FindControl(form, replace(parameters.stateName, '@', ''));
        const controlHjStateId: FormControl = FindControl(form, replace(parameters.hjStatId, '@', ''));
        const controlHjStateName: FormControl = FindControl(form, replace(parameters.hjStateName, '@', ''));

        if (controlHomeJuris.value == 1 || controlHomeJuris.value == 2 || controlHomeJuris.value == 173 || controlHomeJuris.value == 446
            || controlHomeJuris.value == 280 || controlHomeJuris.value == 281 || controlCountry.value == 227
            || ((controlHomeJuris.value == 301 || controlHomeJuris.value == 302) && (controlCountry.value == 227 || controlCountry.value == null))) {
            if (questionTriggering.key == replace(parameters.statId, '@', '')) {
                controlHjStateId.setValue(controlStateId.value);
                controlStateName.setValue(null);
                controlHjStateName.setValue(null);
            }
        }
        else {
            if (questionTriggering.key == replace(parameters.stateName, '@', '')) {
                var field = controlStateName.value;
                if (controlStateName.value != null) {
                    field = field.toUpperCase();
                }

                controlStateName.setValue(field);
                controlHjStateName.setValue(field);
                controlStateId.setValue(null);
                controlHjStateId.setValue(null);
            }
        }
    }

    setRNFields(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { regType: string, useType: string, purpose: string, type: string }) {
        const regType: FormControl = FindControl(form, replace(parameters.regType, '@', ''));
        const useType: FormControl = FindControl(form, replace(parameters.useType, '@', ''));
        const purpose: FormControl = FindControl(form, replace(parameters.purpose, '@', ''));

        if (parameters.type.toUpperCase() == 'REGTYPE') {
            let newUseType = useType.value;
            let newPurpose = purpose.value;

            if (regType) {
                if (regType.value == 1) {
                    if (useType.value != 1 && useType.value != 2 && useType.value != 3) {
                        newUseType = null;
                        newPurpose = null;
                    }
                    else {
                        newUseType = useType.value;
                        if (purpose.value != 2 && purpose.value != 3 && purpose.value != 4 && purpose.value != 5 && purpose.value != 6
                            && purpose.value != 63 && purpose.value != 64 && purpose.value != 66
                            && purpose.value != 7 && purpose.value != 8 && purpose.value != 220)
                            newPurpose = null;
                        else
                            newPurpose = purpose.value;
                    }
                }
                else if (regType.value == 108) {
                    if (useType.value != 4 && useType.value != 5) {
                        newUseType = null;
                        newPurpose = null;
                    }
                    else {
                        newUseType = useType.value;
                        if (purpose.value != 46 && purpose.value != 50 && purpose.value != 51 && purpose.value != 52 && purpose.value != 53 && purpose.value != 112 && purpose.value != 113
                            && purpose.value != 9 && purpose.value != 10 && purpose.value != 11)
                            newPurpose = null;
                        else
                            newPurpose = purpose.value;
                    }
                }
                else if (regType.value == 113) {
                    if (useType.value != 6 && useType.value != 7) {
                        newUseType = null;
                        newPurpose = null;
                    }
                    else {
                        newUseType = useType.value;
                        newPurpose = null;
                    }
                }
                else if (regType.value == 2) {
                    newUseType = 8;
                    newPurpose = 45;
                }
                else if (regType.value == 3) {
                    newUseType = 8;
                    if (purpose.value != 44 && purpose.value != 55 && purpose.value != 54)
                        newPurpose = null;
                    else
                        newPurpose = purpose.value;
                }
                else if (regType.value == 110) {
                    newUseType = 8;
                    newPurpose = 47;
                }
                else if (regType.value == 112) {
                    newUseType = 9;
                    newPurpose = 43;
                }

                useType.setValue(newUseType);
                purpose.setValue(newPurpose);
            }
        }

        if (parameters.type.toUpperCase() == 'USETYPE') {
            let newPurpose = purpose.value;

            if (useType) {
                if (useType.value == 1) {
                    if (purpose.value != 2 && purpose.value != 3 && purpose.value != 4 && purpose.value != 5 && purpose.value != 6)
                        newPurpose = null;
                    else
                        newPurpose = purpose.value;
                }
                else if (useType.value == 2) {
                    if (purpose.value != 7 && purpose.value != 8)
                        newPurpose = null;
                    else
                        newPurpose = purpose.value;
                }
                else if (useType.value == 3) {
                    if (purpose.value != 63 && purpose.value != 64 && purpose.value != 65 && purpose.value != 66 && purpose.value != 67)
                        newPurpose = null;
                    else
                        newPurpose = purpose.value;
                }
                else if (useType.value == 4) {
                    if (purpose.value != 46 && purpose.value != 50 && purpose.value != 51 && purpose.value != 52 && purpose.value != 53 && purpose.value != 112 && purpose.value != 113)
                        newPurpose = null;
                    else
                        newPurpose = purpose.value;
                }
                else if (useType.value == 5) {
                    if (purpose.value != 9 && purpose.value != 10 && purpose.value != 11)
                        newPurpose = null;
                    else
                        newPurpose = purpose.value;
                }

                purpose.setValue(newPurpose);
            }
        }
    }

    setReserveNameEntityType(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { registerType: string, entityType: string, type: string }) {
        const registerType: FormControl = FindControl(form, replace(parameters.registerType, '@', ''));
        const entityType: FormControl = FindControl(form, replace(parameters.entityType, '@', ''));
        if (parameters.type.toUpperCase() == 'REGTYPE') {
            if (registerType) {
                if (registerType.value == 108) // If the selected RegisterType is EP 
                {
                    entityType.setValue(8);// set the value to be Reserve Name
                }
            }
        }
        else {
            if (parameters.type.toUpperCase() == 'NAMESRSH') {
                if (questionTriggering) {
                    if (questionTriggering.value != "") {
                        entityType.setValue(questionTriggering.value);
                    }
                }
            }
        }
    }

    setRelDescription(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { fName: string, lName: string, isADirector: string, isAnOfficer: string, OfficeHeld: string, Other: string, setField: string, entitySuffix: string, personInd: string }) {
        const firstName: FormControl = FindControl(form, replace(parameters.fName, '@', ''));
        const lastName: FormControl = FindControl(form, replace(parameters.lName, '@', ''));
        const entitySuffix: FormControl = FindControl(form, replace(parameters.entitySuffix, '@', ''));

        const director: FormControl = FindControl(form, replace(parameters.isADirector, '@', ''));
        const officer: FormControl = FindControl(form, replace(parameters.isAnOfficer, '@', ''));
        const officeHeld: FormControl = FindControl(form, replace(parameters.OfficeHeld, '@', ''));
        const other: FormControl = FindControl(form, replace(parameters.Other, '@', ''));
        const personInd: FormControl = FindControl(form, replace(parameters.personInd, '@', ''));

        const controlToCopyTo: FormControl = FindControl(form, replace(parameters.setField, '@', ''));

        if (personInd.value == true) {
            let relDesc = " ( ";

            if (officer.value == true) {
                var offHeld = officeHeld.value;

                if (offHeld != null && offHeld != "") {
                    var newArr = [];
                    var ind = -1;
                    offHeld.forEach(item => {
                        ind = -1;
                        newArr.forEach(i => {
                            if (i) {
                                if (i.toString() == item.toString()) {
                                    ind = i;
                                }

                            }
                        });

                        if (ind == -1) {
                            newArr.push(item);
                        }
                    });
                    offHeld = newArr.sort();

                    var newOfficeHeld: string = offHeld;

                    if (director.value == true) {
                        relDesc = relDesc + "Director, ";
                    }

                    relDesc = relDesc + "Officer - ";

                    newOfficeHeld = replace(newOfficeHeld, '1', ' President');
                    newOfficeHeld = replace(newOfficeHeld, '2', ' Vice-president');
                    newOfficeHeld = replace(newOfficeHeld, '3', ' Secretary');
                    newOfficeHeld = replace(newOfficeHeld, '4', ' Treasurer');
                    newOfficeHeld = replace(newOfficeHeld, '5', ' ' + other.value);

                    newOfficeHeld = newOfficeHeld.trim();
                    relDesc = relDesc + newOfficeHeld;
                    relDesc = relDesc + " )";
                }
            }
            else {
                relDesc = "( Director )";
            }

            var upperFirst = firstName.value;
            var upperLast = lastName.value;

            if (upperFirst != null) {
                upperFirst = upperFirst.toUpperCase();
                firstName.setValue(upperFirst);
            }
            if (upperLast != null) {
                upperLast = upperLast.toUpperCase();
                lastName.setValue(upperLast);
            }

            entitySuffix.setValue(relDesc);
            controlToCopyTo.setValue(upperFirst + " " + upperLast + " " + relDesc);
        }

    }


    setNewHJQuestions(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { type: string, country: string, state: string, copyTo: string }) {
        const controlType: FormControl = FindControl(form, replace(parameters.type, '@', ''));
        const controlCountry: FormControl = FindControl(form, replace(parameters.country, '@', ''));
        const controlState: FormControl = FindControl(form, replace(parameters.state, '@', ''));
        const controlToCopyTo: FormControl = FindControl(form, replace(parameters.copyTo, '@', ''));

        var options: any[] = [];
        options = new Array();

        if (questionTriggering.key == replace(parameters.type, '@', '')) {
            if (controlType.value == 1) {
                controlToCopyTo.setValue("CANADA");
            }
            if (controlType.value == 2) {
                controlToCopyTo.setValue("CANADA");
            }
            if (controlType.value == 3) {
                controlToCopyTo.setValue('');
            }
        }
        if (questionTriggering.key == replace(parameters.country, '@', '')) {
            if (controlType.value == 3 && controlCountry.value != "UNITED STATES") {
                controlToCopyTo.setValue('');
            }
        }
        if (questionTriggering.key == replace(parameters.state, '@', '')) {
            if (controlType.value == 1) {
                controlToCopyTo.setValue('');
            }
        }
    }

    setCountry(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { entityId: string, country: string, line1: string }) {
        const controlEntityId: FormControl = FindControl(form, replace(parameters.entityId, '@', ''));
        const controlCountry: FormControl = FindControl(form, replace(parameters.country, '@', ''));
        const controlLine1: FormControl = FindControl(form, replace(parameters.line1, '@', ''));

        if (controlEntityId.value == null && controlLine1.value == "")
            controlCountry.setValue(38);
    }


    setQuestion(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { field1: string, field2: string, copyTo: string, type: string }) {
        const control1: FormControl = FindControl(form, replace(parameters.field1, '@', ''));
        const controlToCopyTo: FormControl = FindControl(form, replace(parameters.copyTo, '@', ''));

        controlToCopyTo.setValue(control1.value);
    }

    setRelTypeArray(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { relList: string, personInd: string }) {
        const controlRelList: FormControl = FindControl(form, replace(parameters.relList, '@', ''));
        const controlpersonInd: FormControl = FindControl(form, replace(parameters.personInd, '@', ''));

        if (controlpersonInd.value != null && controlRelList.value != null) {
            if (controlpersonInd.value == false) {
                //Check to see if Officer in array, if yes, then remove
                if (controlRelList.value.includes("3")) {
                    for (var i = 0; i < controlRelList.value.length; i++) {
                        if (controlRelList.value[i] == "3") {
                            controlRelList.value.splice(i);
                        }
                    }
                    controlRelList.setValue(controlRelList.value);
                }
            }
        }
    }

    setFieldUppercase(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { field: string }) {
        const inField: FormControl = FindControl(form, replace(parameters.field, '@', ''));

        if (inField.value != null) {
            var upperField = inField.value;
            upperField = upperField.toUpperCase();
            inField.setValue(upperField);
        }
    }

    subtract(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { subFrom: string, subTo: string, copyTo: string }) {
        let sub = '0';
        const control1: FormControl = FindControl(form, replace(parameters.subFrom, '@', ''));
        const control2: FormControl = FindControl(form, replace(parameters.subTo, '@', ''));
        if (control1 && control1.value != "" && control2 && control2.value != "") {
            sub = (Number(control1.value) - Number(control2.value)).toLocaleString();
        }
        const controlToCopyTo: FormControl = FindControl(form, replace(parameters.copyTo, '@', ''));
        controlToCopyTo.setValue(sub);
    }

    PopulateDDwithListValues(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { valFrom: string }) {
        var options: any[] = [];
        options = new Array();
        const controlToCopyTo: FormControl = FindControl(form, replace(parameters.valFrom, '@', ''));
        if (controlToCopyTo.value instanceof Array) {
            controlToCopyTo.value.forEach((itm) => {
                var itmStr: string = JSON.stringify(itm);
                var JSONItems = JSON.parse(itmStr, (k, v) => {
                    return v;
                });
                let itemval = JSONItems['00000001-0001-0001-0000-000000000002'];
                options.push({ key: itemval, value: itemval });
            })
        }
        questionTriggering.options = options;
    }

    PopulateDDwithRelationshipValues(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { entityId: string, dropdown: string, relList: string }) {
        const controlEntityId: FormControl = FindControl(form, replace(parameters.entityId, '@', ''));
        let controlDropdown: RegSysFormControl = FindControl(form, replace(parameters.dropdown, '@', ''));

        let relationshipList: string = parameters.relList;
        let options: any[] = [];
        controlDropdown.options = new Array();

        if (questionTriggering.key == replace(parameters.dropdown, '@', '')) {

            var error = false;

            if (isNaN(Number(questionTriggering.value))) {
                error = true;
            }


            if (!error) {

                if (controlEntityId.value != null) {
                    const queryString = "?EntityId=" + controlEntityId.value + "&RelationshipTypeIdList=" + relationshipList + "&RelationshipCurrentInd=Y";
                    const request: GetRequest = {
                        url: `${environment.apiUrl}rjscentity/relationships` + queryString,
                        getFullResponse: true
                    }

                    regsysHttpSuper.doGet(request).subscribe((httpResponse: any) => {
                        var res: any[] = httpResponse.data;
                        var unique: any[] = [];

                        var exists = false;

                        res.forEach(element => {
                            unique.forEach(item => {
                                if (item.entityRegNumber + " - " + item.entityRegName == element.entityRegNumber + " - " + element.entityRegName) {
                                    exists = true;
                                }
                            });
                            if (exists == false) {
                                unique.push(element);
                            }
                            exists = false;
                        });

                        unique.forEach(element => {
                            options.push({ key: element.entityRegName.toUpperCase(), value: element.entityRegName.toUpperCase() });
                        });

                        questionTriggering.options = options;
                    });
                }
                else {
                    questionTriggering.options = options;
                }
            }
        }
        else {
            controlDropdown.setValue(controlDropdown.value);
        }
    }


    setNSAsDefaultState(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { setStateFor: string, countryDDL: string }) {
        const controlState: FormControl = FindControl(form, replace(parameters.setStateFor, '@', ''));
        const controlCountry: FormControl = FindControl(form, replace(parameters.countryDDL, '@', ''));


        if (controlState && controlCountry && (controlState.value == null || controlState.value == "undefined") && controlCountry.value == 38) {
            controlState.setValue(8);
        }
    }

    setProvState(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { textState: string, setProv: string, setState: string, countryDDL: string }) {
        const controlTextState: FormControl = FindControl(form, replace(parameters.textState, '@', ''));
        const controlCountry: FormControl = FindControl(form, replace(parameters.countryDDL, '@', ''));
        const controlProv: FormControl = FindControl(form, replace(parameters.setProv, '@', ''));
        const controlState: FormControl = FindControl(form, replace(parameters.setState, '@', ''));
        var textState = controlTextState.value;

        if (controlTextState && controlCountry && controlProv && controlState) {
            if (controlTextState.value != null && controlTextState.value != "" && controlCountry.value != 38 && controlCountry.value != 227) {
                controlProv.setValue("undefined");
                controlState.setValue("undefined");
                controlTextState.setValue(textState.toUpperCase());
            }
        }
    }


    ClearCtrlValue(questionTriggering: QuestionBase<any>, form: FormGroup, parameters: { Ctrl: string }) {
        const ControlCtrl: FormControl = FindControl(form, replace(parameters.Ctrl, '@', ''));
        if (ControlCtrl.status != "VALID") {
            ControlCtrl.setValue("");
        }
    }


}

function removeErrors(keys: string[], control: AbstractControl) {
    if (!control || !keys || keys.length === 0) {
        return;
    }

    const remainingErrors = keys.reduce((errors, key) => {
        delete errors[key];
        return errors;
    }, { ...control.errors });

    control.setErrors(remainingErrors);

    if (Object.keys(control.errors || {}).length === 0) {
        control.setErrors(null);
    }
}


function addErrors(errors: { [key: string]: any }, control: AbstractControl) {
    if (!control || !errors) {
        return;
    }

    control.setErrors({ ...control.errors, ...errors });
}
