import { ClientConfig } from './client-variables';
import { PaymentTypes } from '@regsys/payment';

export const environment = {
  ...ClientConfig,
  production: true,
  apiUrl: 'https://api.rjsc.novascotia.ca/api/',
  whitelistDomains: ['api.rjsc.novascotia.ca'],
  paymentOptions: {
    paymentProvider: 'GPS',
    paypal: {
      paypalBaseUrl: 'https://api.sandbox.paypal.com/v1',
      paypal_env: 'sandbox',
    },
    stripe: {
      stripe_pk: 'pk_test_kFxgSO9anTDYTyNq9U2c5SZq'
    },
    realex: {
      trustedUrl: 'https://pay.sandbox.realexpayments.com',
      submitUrl: 'https://pay.sandbox.realexpayments.com/pay'
    },
      types: [PaymentTypes.Account, PaymentTypes.CreditCard],
    gps: {
      submitUrl: 'https://payments.novascotia.ca/'
      },
      magicpay: {
          apiBaseUrl: 'https://stage.collectorsolutions.com/magic-api/api/',
          transactionRedirectApiPartialUrl: 'transaction/redirect',
          redirectUrl: 'https://stage.collectorsolutions.com/magic-ui/VirtualTerminal/',
      }
  },
  captcha: {
    key: '6LeCcgoUAAAAAB4mO_LLHsHDnMibWcrg6-HfRvzD'
  },
  version: '6.3.2'
};

