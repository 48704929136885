import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RegsysHttp, PostRequest } from '@regsys/http';

import { SearchRequest } from '../models/search-request.model';
import { OptionItem } from '../models/option-item.model';
import { environment } from '../../environments/environment';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class PersonSearchService {

    constructor(public regSysHttp: RegsysHttp) { }

    search(search: SearchRequest): Observable<any> {
        const request: PostRequest = {
            url: `${environment.apiUrl}rjscentity/entity-quick-search-person`,
            parameters: search
        };

        return this.regSysHttp.doPost(request);
    }

    getStatesDdl(): OptionItem[] {

        const statesDdl = new Array<OptionItem>();

        this.getStates().subscribe(
            res => {
                res.data.forEach(item => {
                    statesDdl.push(new OptionItem(item.stateId, item.stateName, item.countryId));
                });
            }
        );

        return statesDdl;
    }

    private getStates(): Observable<any> {
        const request = { url: `${environment.apiUrl}configuration/states` };
        return this.regSysHttp.doGet(request);
    }
}