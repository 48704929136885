import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { FourOFourComponent } from './404/404.component';
import { UnknownErrorComponent } from './unknown-error/unknown-error.component';
import { HomeComponent } from './home/home.component';
import { SSOLogoutComponent } from './ssologout/ssologout.component';
import { SearchByPersonComponent } from '../components/search-by-person/search-by-person.component';
import { SearchResultsGridComponent } from '../components/search-results-grid/search-results-grid.component';
import { PersonSearchComponent } from './person-search/person-search.component';
import { LobCatalogComponent } from './catalog/lob.catalog.component';
import { EntityEmployerDetailsComponent } from './catalog/components/entity-employer-details/entity-employer-details.component';
import { EntityClientDetailsComponent } from './catalog/components/entity-client-details/entity-client-details.component';
import { EntityLobbyingActivityComponent } from './catalog/components/entity-lobbying-activity/entity-lobbying-activity.component';
import { EntityGovernmentFundingComponent } from './catalog/components/entity-government-funding/entity-government-funding.component';
import { EntityPrivateFundingComponent } from './catalog/components/entity-private-funding/entity-private-funding.component';

export const containers = [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    FourOFourComponent,
    UnknownErrorComponent,
    HomeComponent,
    SSOLogoutComponent,
    SearchResultsGridComponent,
    SearchByPersonComponent,
    PersonSearchComponent,
    LobCatalogComponent,
    EntityEmployerDetailsComponent,
    EntityClientDetailsComponent,
    EntityLobbyingActivityComponent,
    EntityGovernmentFundingComponent,
    EntityPrivateFundingComponent
];

export { AppComponent } from './app.component';
