<!-- Application footer -->
<footer class="rs-footer">
    <!-- Main footer -->
    <section class="rs-main-footer" >
   
      <!-- Links block -->
        <div class="block-right">
            <ul class="links-column">
                <li><a [href]="'footerComponent.privacyLink' | translate" target="_blank" >{{ 'footerComponent.Privacy' | translate }}</a></li>
            </ul>
            <ul class="links-column">
                <li><a [href]="'footerComponent.termsLink' | translate" target="_blank" >{{ 'footerComponent.Terms' | translate }}</a></li>
            </ul>
            <ul class="links-column">
                <li><a [href]="'footerComponent.cookiesLink' | translate" target="_blank" >{{ 'footerComponent.cookies' | translate }}</a></li>
            </ul>
            <br />

        </div>
      <div class="block-right" >
        <ul class="links-column">
            <li> <div ><small style="color: white;">{{ 'footerComponent.copyright' | translate }}</small></div>
            </li>
          </ul>
        
        
      </div>
     
    </section>

  
 
  </footer>
  