import { Component, OnInit, Renderer2, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationEvent, SystemLanguage, CommonConfigService, ICommonConfig, CommonConfig } from '@regsys/common';
import { SessionStorageService, storageServiceConstants } from '@regsys/http';
import { SecurityEvent, AuthService, CustomerUser } from '@regsys/security';
import { NotificationAggregator, ErrorNotification, InternalNotificationType, InternalNotification } from '@regsys/notifications';
import { Subscription } from 'rxjs';

@Component({
    selector: 'regsys-localization',
    templateUrl: './app-localization.component.html'
})
export class LocalizationComponent implements OnInit, OnDestroy {

    storageServiceConstants = storageServiceConstants;
    supportedLanguages: SystemLanguage[] = [];
    languageSelected: string;

    private config: ICommonConfig;
    private translationsSubscription: Subscription;

    constructor(
        @Inject(DOCUMENT) private document: any,
        @Inject(CommonConfigService) commonConfig: CommonConfig,
        private sessionStorageService: SessionStorageService,
        private translateService: TranslateService,
        private securityEvent: SecurityEvent,
        private authService: AuthService,
        private notificationsService: NotificationAggregator,
        private localizationEventService: LocalizationEvent,
        private na: NotificationAggregator) {

        this.config = commonConfig.config;
    }

    ngOnInit() {
        this.displayErrors();
        this.verifyTimezoneLoadedCorrectly();
        this.supportedLanguages = this.sessionStorageService.GetValue<SystemLanguage[]>(storageServiceConstants.supportedLanguages);
        const currentLanguage = this.sessionStorageService.GetValue<SystemLanguage>(storageServiceConstants.currentLanguage);
        this.languageSelected = currentLanguage ? currentLanguage.systemLanguageDesc : this.config.defaultLanguage;
        this.setUserLanguage(this.authService.isLoggedIn());
        this.setEventListeners();
    }

    ngOnDestroy() {
        if (this.translationsSubscription) {
            this.translationsSubscription.unsubscribe();
        }
    }

    changeLanguage(lang: string) {
        const missingLanguages =
            this.sessionStorageService.GetValue<SystemLanguage[]>(storageServiceConstants.missingLanguageConfig) as SystemLanguage[];

        if (missingLanguages && missingLanguages.some(x => x.systemLanguageValue === lang)) {
            this.showErrorMessage();
            return;
        }
        const selectedLanguage = this.supportedLanguages.find(language => language.systemLanguageValue === lang);
        if (!selectedLanguage) {
            this.showErrorMessage();
            return;
        }
        this.document.documentElement.lang = selectedLanguage.systemLanguageValue;
        this.languageSelected = selectedLanguage.systemLanguageDesc;
        this.sessionStorageService.SetValue(storageServiceConstants.currentLanguage, selectedLanguage);
        this.translateService.use(selectedLanguage.systemLanguageValue);
        this.na.publish(new InternalNotification(InternalNotificationType.language));
    }

    private verifyTimezoneLoadedCorrectly() {
        if (this.sessionStorageService.GetValue<boolean>(storageServiceConstants.failedToLoadTimezone)) {
            const timezone = this.sessionStorageService.GetValue<string>(storageServiceConstants.timezone);
            this.notificationsService.publish(new ErrorNotification('initializerServices.timezoneFailedToLoad', true, { timezoneName: timezone }));
        }
    }

    private loadUserPreferredLanguage(custUser: CustomerUser) {
        const supportedLanguages = this.sessionStorageService.GetValue<SystemLanguage[]>(storageServiceConstants.supportedLanguages);
        const selectedLanguage = supportedLanguages.find(x => x.systemLanguageId === custUser.preferredLanguageId);
        if (selectedLanguage) {
            this.changeLanguage(selectedLanguage.systemLanguageValue);
        }
    }

    private setEventListeners() {
        this.localizationEventService.languageChanged.subscribe((res: string) => {
            this.changeLanguage(res);
        });

        this.securityEvent.loggedIn.subscribe(isLoggedIn => this.setUserLanguage(isLoggedIn));
    }

    private setUserLanguage(isLoggedIn: boolean) {
        if (!isLoggedIn) {
            return;
        }

        const custUser = this.authService.getAuthCustomerUser();
        if (custUser != null) {
            if (custUser.preferredLanguageId) {
                this.loadUserPreferredLanguage(custUser);
            }
        }
    }

    private showErrorMessage() {
        this.notificationsService.publish(new ErrorNotification('localizationComponent.cannotLoadLanguage'));
    }

    private displayErrors() {
        if (this.sessionStorageService.GetValue<boolean>(storageServiceConstants.errorLoadingLanguages)) {
            this.notificationsService.publish(new ErrorNotification('initializerServices.problemLoadingLanguages'));
        }
        if (this.sessionStorageService.GetValue<boolean>(storageServiceConstants.failedToLoadFormLocalization)) {
            //this.notificationsService.publish(new ErrorNotification('initializerServices.problemLoadingFormLocalizations'));
        }


        if (this.translateService.currentLang && this.translateService.translations) {
            // wait for translations to load and populate before checking for 'forms' section
            this.translationsSubscription = this.translateService.use(this.translateService.currentLang).subscribe(translations => {
                if (translations && Object.keys(translations).length && !translations.forms) {
                    //this.notificationsService.publish(new ErrorNotification('initializerServices.problemLoadingFormLocalizations'));
                }
            });
        }
    }
}
