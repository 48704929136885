import { ParseStringDate } from './parse-string-date.function';

import moment from 'moment';
import forEach from 'lodash-es/forEach';
import orderBy from 'lodash-es/orderBy';

export function SortGridDates(collectionToSort: any[], dateFormat: string, propertyToSortBy: any, sortDirection: any) {
    forEach(collectionToSort, (o) => {
        // Change the property value in the class to a date
        const itemDate = ParseStringDate(o[propertyToSortBy], dateFormat);
        o[propertyToSortBy] = itemDate;
    });

    // Sort the list based on the property name
    const sortedList = orderBy(collectionToSort, x => x[propertyToSortBy], sortDirection);

    // Change the value back to string
    forEach(sortedList, (o) => {
        // Change the property value in the class to a date
        const itemDate = o[propertyToSortBy];
        const dateString = moment(itemDate).format(dateFormat);
        o[propertyToSortBy] = dateString;
    });
    return sortedList;
}
