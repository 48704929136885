import { SubscriberItem } from './SubscriberItem';
export class COOPbyLawsReportParam {
    entityRegName: string;
    entityRegNumber: string;
    objective:string;
    addressLine1:string;
    addressLine2:string;
    addressLine3:string;
    city:string;
    postCode:string;
    fiscalYearEnd:string;
    memberQuorumType:number;
    memberQuorumNumber:number;
    memberQuorumPercent:number;
    minDir:number;
    maxDir:number;
    termYears:number;
    maxTerm:number;
    dirMeetingTimes:number;
    dirQuorumType:number;
    dirQuorumNumber:number;
    dirQuorumPercent:number;
    membershipFee:string;
    memberLoans:boolean;
    maxMemberLoans:boolean;
    borrowingAmount: string;
    coopType: number;
    limitedBy: number;
    shareValue: string;
    minNumShares: number;
    genReservePercent: string;
    genReserveSurplus: string;
    hTMLTemplate:string;
    subscribers: SubscriberItem[]
}
