import { Component, OnInit, ViewChild, AfterContentChecked } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { tap, debounceTime, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import filter from 'lodash-es/filter';

import { QuickSearchFilterPipe } from '@regsys/common';
import { RegSysDataSource, rsFadeAnimation, rsListAnimation } from '@regsys/web';
import { ErrorCodes } from '@regsys/http';
import { WarningNotification, NotificationAggregator } from '@regsys/notifications';

import { environment } from '../../../../environments/environment';
import { HomeSearchService } from '../../../services/home-search.service';

@Component({
    selector: 'regsys-public-search',
    templateUrl: './public-search.component.html',
    animations: [rsFadeAnimation, rsListAnimation]
})
export class PublicSearchComponent implements OnInit, AfterContentChecked {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) gridSort: MatSort;

    quickterm: FormControl;

    searchResponse: any;
    isSearching = false;
    isEmptyResult = false;

    displayedColumns = [
        'registeredNumber',
        'registeredName',
        'entityTypeDesc',
        'entityId'
    ];
    dataSource: RegSysDataSource;

    hideResults: boolean;

    protected searchFilter = environment.searchOptions.quickSearchFilter;
    protected minimumCharacters = environment.searchOptions.minimumCharacters;

    constructor(
        private homeSearchService: HomeSearchService,
        private router: Router,
        private na: NotificationAggregator
    ) { }

    ngOnInit() {

        this.quickterm = new FormControl();

        this.quickterm.valueChanges.pipe(
            tap((term: string) => {
                this.isSearching = false;
                this.isEmptyResult = false;
                if (!term || term.length === 0) {
                    this.searchResponse = undefined;
                }
            }),
            debounceTime(1000),
            tap(() => {
                this.isSearching = true;
                this.hideResults = false;
            }),
            switchMap(term => this.doQuickSearch(term))
        ).subscribe((res: any) => {
            this.isSearching = false;
            if (!res.skip) {

                if (res.statusCode === ErrorCodes.tooManyRequests) {
                    this.na.publish(new WarningNotification('validationComponent.reachedCallsLimit'));
                    this.searchResponse = undefined;
                    return;
                }
                res.data = this.runDefaultFilter(res.data);
                if (this.searchFilter) {
                    res.data = this.runFilter(res.data);
                }

                this.isEmptyResult = !(res.data && res.data.length > 0);
                this.searchResponse = res.data;

                this.setDataSource();
            }
        });
    }

    ngAfterContentChecked() {
        this.setDataSource();
    }

    onClick(entityId: number) {
        this.router.navigate(['e-commerce/company', entityId]);
    }

    onHideResults() {
        this.quickterm.reset();
        this.hideResults = true;
    }

    private doQuickSearch(term: string): Observable<any> {
        if (term && term.length > this.minimumCharacters) {
            return this.homeSearchService.entityQuickSearch(term);
        } else {
            return of({ skip: true });
        }
    }

    private runFilter(list: any[]) {
        const pipe = new QuickSearchFilterPipe();
        const filteredData = pipe.transform(list, this.searchFilter);
        return filteredData;
    }

    protected runDefaultFilter(results: any[]): any[] {
        return filter(results, (x: any) => {
            return !x.entityExpiryDate || new Date(x.entityExpiryDate) >= new Date();
        });
    }

    private setDataSource() {
        if (this.paginator && this.gridSort) {
            this.dataSource = new RegSysDataSource(this.searchResponse, this.paginator, this.gridSort);
            return;
        }

        if (this.searchResponse && this.dataSource && !this.dataSource.isSameData(this.searchResponse)) {
            this.dataSource.refreshData(this.searchResponse);
            return;
        }
    }

}
